import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./QALabel.module.css";

const QALabel = () => {
  const navigate = useNavigate();

  const onListItem16Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onListItem17Click = useCallback(() => {
    navigate("/csqa-page");
  }, [navigate]);

  const onRectangleShapeClick = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  return (
    <div className={styles.qALabel}>
      <div className={styles.csWrapper}>
        <h2 className={styles.cs}>CS</h2>
      </div>
      <div className={styles.listItem} onClick={onListItem16Click}>
        Notice
      </div>
      <div className={styles.helpText}>
        <div className={styles.companyInfo}>
          <div
            className={styles.listItem1}
            onClick={onListItem17Click}
          >{`Q&A`}</div>
          <div className={styles.chatListFrame}>
            <div className={styles.chatItemFrame}>
              <div className={styles.listItemLinkNoticeParent}>
                <div className={styles.listItem2}>QnA</div>
                <img className={styles.frameChild} alt="" />
              </div>
              <div className={styles.registrationButtonWrapper}>
                <div className={styles.registrationButton}>
                  <div className={styles.listItemLinkNoticeWrapper}>
                    <div className={styles.listItem3}>현금영수증 요청</div>
                  </div>
                  <div className={styles.frameParent}>
                    <div className={styles.listItemLinkNoticeContainer}>
                      <div className={styles.listItem4}>고****</div>
                    </div>
                    <div className={styles.listItemLinkNoticeFrame}>
                      <div className={styles.listItem5}>2024.02.05</div>
                    </div>
                    <div className={styles.div}>
                      <div
                        className={styles.rectangleShape}
                        onClick={onRectangleShapeClick}
                      />
                      <div className={styles.deleteLabel}>
                        <div className={styles.div1}>삭제</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.listItemLinkNoticeGroup}>
                <div className={styles.listItem6}>현금영수증 요청합니다</div>
                <img
                  className={styles.frameItem}
                  loading="lazy"
                  alt=""
                  src="/line-7.svg"
                />
              </div>
              <div className={styles.chatItemFrameInner}>
                <div className={styles.frameDiv}>
                  <div className={styles.listItem7}>관리자</div>
                  <div className={styles.listItem8}>2024.02.05 15:03</div>
                </div>
              </div>
              <div className={styles.listItemLinkNoticeParent1}>
                <div className={styles.listItem9}>발급해 드렸습니다.</div>
                <div className={styles.monFriLabel}>
                  <div className={styles.chatListItemFrame} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.helpTextInner}>
          <div className={styles.monFriTextParent}>
            <div className={styles.monFriText} />
            <div className={styles.weekdaysLabel}>
              <div className={styles.weekdaysLabelChild} />
              <div className={styles.div2}>등록</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QALabel;
