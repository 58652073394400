import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./BrandStory1.module.css";
import { useTranslation } from 'react-i18next'

const BrandStory1 = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onDivClick = useCallback(() => {
    navigate("/brandstory2");
  }, [navigate]);

  const onDiv1Click = useCallback(() => {
    navigate("/brandstory3");
  }, [navigate]);

  const onDiv2Click = useCallback(() => {
    navigate("/brandstory4");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.brandstory1}>
      <header className={styles.frameBrandStory}>
        <nav className={styles.headerListItemLinkParent}>
          <div className={styles.headerList} onClick={onHeaderList3Click}>
            Shop
          </div>
          <div className={styles.headerList1} onClick={onHeaderListClick}>
            Brand
          </div>
          <div className={styles.headerList3} onClick={onHeaderList1Click}>
            CS
          </div>
        </nav>
        <div className={styles.headerList4} onClick={onHeaderList4Click}>
          Cart
        </div>
      </header>
      <div className={styles.onSpaLabel}>
        <div className={styles.innerFrame}>
          <div className={styles.framedailyskinbodycare}>
            <div className={styles.textdailyskinbodycare}>
              <div className={styles.dailySkin}>{`daily skin & body care`}</div>
              <div className={styles.textmyfavoritewater}>
                <div className={styles.div}>
                  <p className={styles.p}>{t('brand1-1')}</p>
                  <p className={styles.p1}>{t('brand1-2')}</p>
                </div>
                <div className={styles.div1}>
                  <p className={styles.p2}>{t('brand1-3')}</p>
                  <p className={styles.p3}>{t('brand1-4')}</p>
                  <p className={styles.p4}>
                    {t('brand1-5')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h2 className={styles.onSpa}>On spa</h2>
        </div>
      </div>
      <main className={styles.activeDivWrapper}>
        <section className={styles.divhinokAboutWrap}>
          <img
            className={styles.divswiperSlideIcon}
            alt=""
            src="/BS1.jpg"
          />
        </section>
        <div className={styles.divactive} />
        <div className={styles.div2} onClick={onDivClick} />
        <div className={styles.div3} onClick={onDiv1Click} />
        <div className={styles.div4} onClick={onDiv2Click} />
        <img
          className={styles.headerListItemLink}
          loading="lazy"
          alt=""
          src="/login_white.png"
          onClick={onHeaderListItemLinkClick}
        />
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoWhite.png"
          onClick={onImageClick}
        />
        {/*<img*/}
        {/*  className={styles.instagram1Icon}*/}
        {/*  loading="lazy"*/}
        {/*  alt=""*/}
        {/*  src="/instagram.png"*/}
        {/*/>*/}
      </main>
    </div>
  );
};

export default BrandStory1;
