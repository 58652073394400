import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { AuthProvider } from './AuthContext';
import NoticeMain from "../pages/Notice/NoticeMain";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import NoticeDetail from "../pages/Notice/NoticeDetail";
import NoticePost from "../pages/Notice/NoticePost";
import NoticeUpdate from "../pages/Notice/NoticeUpdate";
import QnaUpdate from "../pages/Qna/QnaUpdate";
import QnaDetail from "../pages/Qna/QnaDetail";
import QnaPost from "../pages/Qna/QnaPost";
import QnaMain from "../pages/Qna/QnaMain";
import ItemMain from "../pages/Item/ItemMain";
import ItemPost from "../pages/Item/ItemPost";
import ItemDetail from "../pages/Item/ItemDetail";
import ItemUpdate from "../pages/Item/ItemUpdate";
import Main from "../pages/Main/Main";
import Login from "../pages/Login/Login";
import Signup from "../pages/Login/Signup";
import Cart from "../pages/Cart/Cart";
import UserProfile from "../pages/Mypage/UserProfile";
import Orders from "../pages/Mypage/Orders";
import Payment from "../pages/Payment/payment";
import TermsofUse from "../pages/Policy/TermsofUse";
import PrivacyPolicy from "../pages/Policy/PrivacyPolicy";
import BrandStory from "../pages/Brand/BrandStory3";
import BrandStory1 from "../pages/Brand/BrandStory1";
import BrandStory2 from "../pages/Brand/BrandStory2";
import BrandStory4 from "../pages/Brand/BrandStory4";
import BrandStory3 from "../pages/Brand/BrandStory3";
import JoinAgree from "../pages/Login/JoinAgree";
import OrderDone from "../pages/Order/OrderDone";
import Order from "../pages/Order/Order";
import MainPage from "../pages/Main/MainPage";
import ProductPage from "../pages/Item/ProductPage";
import ProductPageSerum from "../pages/Item/ProductPageSerum";
import ProductPageSerumSet from "../pages/Item/ProductPageSerumSet";
import ProductPageSerumMistSet from "../pages/Item/ProductPageSerumMistSet";
import ProductPageMist from "../pages/Item/ProductPageMist";
import ProductPageMistSet from "../pages/Item/ProductPageMistSet";
import ShoppingCart from "../pages/Cart/ShoppingCart";
import ItemWrapper from "./ItemWrapper";
import Join from "../pages/Login/Join";
import CSMyPageProfile from "../pages/Mypage/CSMyPageProfile";
import CSMyPageOrder from "../pages/Mypage/CSMyPageOrder";
import CSMyPageOrder1 from "../pages/Mypage/CSMyPageOrder1";
import CSNoticeWrite from "../pages/Notice/CSNoticeWrite";
import CSNoticeUpdate from "../pages/Notice/CSNoticeUpdate";
import CSNoticePage from "../pages/Notice/CSNoticePage";
import CSNoticeDetail from "../pages/Notice/CSNoticeDetail";
import CSQnaUpdate from "../pages/Qna/CSQnaUpdate";
import CSQAPage from "../pages/Qna/CSQAPage";
import CSQnaDetail from "../pages/Qna/CSQnaDetail";
import CSQnaWrite from "../pages/Qna/CSQnaWrite";

function App() {
  return (
      <BrowserRouter>
          <AuthProvider>
          <Routes>
              <Route path="/cart" element={<Cart />} />
              <Route path="/termsofuse" element={<TermsofUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route path="/brandstory1" element={<BrandStory1 />} />
              <Route path="/brandstory2" element={<BrandStory2 />} />
              <Route path="/brandstory4" element={<BrandStory3 />} />
              <Route path="/brandstory3" element={<BrandStory4 />} />

              <Route path="/login" element={<Login />}/>
              <Route path="/joinAgree" element={<JoinAgree />}/>
              <Route path="/join" element={<Join />}/>

              <Route path="/orderdone" element={<OrderDone />} />
              <Route path="/order" element={<Order />} />

              <Route path="/" element={<MainPage />} />

              <Route path="/product-page" element={<ProductPage />} />
              <Route path="/product-pageserum" element={<ProductPageSerum />} />
              <Route path="/product-pageserumset" element={<ProductPageSerumSet />} />
              <Route path="/product-pageserummistset" element={<ProductPageSerumMistSet />} />
              <Route path="/product-pagemist" element={<ProductPageMist />} />
              <Route path="/product-pagemistset" element={<ProductPageMistSet />} />


              <Route path="/pay" element={<Payment />} />

              <Route path="/notice/:noticeId/update" element={<NoticeUpdate />} />
              <Route path="/notice/:noticeId/detail" element={<NoticeDetail />} />
              <Route path="/notice/post" element={<NoticePost />} />
              <Route path="/notice" element={<NoticeMain />} />
              <Route path="/qna/:qnaId/update" element={<QnaUpdate />} />
              <Route path="/qna/:qnaId/detail" element={<QnaDetail />} />
              <Route path="/qna/post" element={<QnaPost />} />
              <Route path="/qna" element={<QnaMain />} />
              <Route path="/mypage/order" element={<Orders />} />
              <Route path="/mypage" element={<UserProfile />} />

              <Route path="/csmy-page-profile" element={<CSMyPageProfile />} />
              <Route path="/csmy-page-order" element={<CSMyPageOrder />} />
              <Route path="/csmy-page-order-2" element={<CSMyPageOrder1 />} />

              <Route path="/csqnawrite" element={<CSQnaWrite />} />
              <Route path="/csqnadetail" element={<CSQnaDetail />} />
              <Route path="/csqa-page" element={<CSQAPage />} />
              <Route path="/csqnaupdate" element={<CSQnaUpdate />} />

              <Route path="/csnoticewrite" element={<CSNoticeWrite />} />
              <Route path="/csnoticeupdate" element={<CSNoticeUpdate />} />
              <Route path="/csnotice-page" element={<CSNoticePage />} />
              <Route path="/csnoticedetail" element={<CSNoticeDetail />} />

              <Route path="/item/:itemId/update" element={<ItemUpdate />} />
              <Route path="/item/:itemId/detail" element={<ItemDetail />} />
              <Route path="/item/:id" element={<ItemWrapper />} />
              <Route path="/item/post" element={<ItemPost />} />
              <Route path="/item" element={<ItemMain />} />

              <Route path="/main" element={<Main />}/>
          </Routes>
          </AuthProvider>
      </BrowserRouter>
  );
}

export default App;
