import {useCallback, useEffect, useState} from "react";
import HeaderRowCell from "./HeaderRowCell";
import {useLocation, useNavigate} from "react-router-dom";
import DaumPost from "../pages/Login/DaumPost";
import { onClickPayment, onClickPaymentTrans } from '../pages/Payment/payment';
import styles from "./CousinOnceRemovedNodes.module.css";
import * as PortOne from "@portone/browser-sdk/v2";
import {useTranslation} from "react-i18next";


const CousinOnceRemovedNodes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { cartItems } = location.state || { cartItems: [] }; // state에서 cartItems를 추출

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  // cartItems가 배열이 아니면 배열로 변환
  if (!Array.isArray(cartItems)) {
    cartItems = [cartItems];
  }


  const [popup, setPopup] = useState(false);
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [zonecode, setZonecode] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [deliveryNote, setDeliveryNote] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const shippingCost = 3000; // 배송비는 3000원으로 가정
  const itemsTotalPrice = cartItems.reduce((total, item) => total + item.salePrice, 0);

  const totalPrice = itemsTotalPrice + shippingCost; // 총 금액에 배송비를 더함

  useEffect(() => {
    const loadScripts = () => {
      const jquery = document.createElement("script");
      jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
      document.head.appendChild(jquery);

      const iamport = document.createElement("script");
      iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
      document.head.appendChild(iamport);

      return () => {
        document.head.removeChild(jquery);
        document.head.removeChild(iamport);
      };
    };

    loadScripts();
  }, []);

  const handleAddress = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    setAddress(fullAddress); // 선택한 주소를 상태에 저장
    setZonecode(data.zonecode); // 우편번호를 상태에 저장
    setPopup(false); // 주소 검색 팝업 닫기
  };


  const handlePayment = useCallback(async() => {
    const paymentData = {
      name,
      phone,
      email,
      address,
      zonecode,
      deliveryNote,
      totalPrice,
      paymentMethod,
      orderId: `mid_${new Date().getTime()}`,
    };
    try {
      // 결제 방식에 따라 함수 호출
      if (paymentMethod === 'creditCard') {
        await onClickPayment(paymentData);
      } else if (paymentMethod === 'virtualAccount') {
        await onClickPaymentTrans(paymentData);
      }

      navigate('/orderDone', { state: paymentData }); // 결제 성공 후 네비게이션
    } catch (error) {
      console.error('Payment error:', error);
      alert('결제 처리 중 오류가 발생했습니다.');
    }
  }, [navigate, name, phone, email, address, zonecode, deliveryNote, totalPrice, paymentMethod]);

  return (
    <div className={styles.cousinOnceRemovedNodes}>
      <div className={styles.ancestorOnceRemovedNodes}>
        <h1 className={styles.order}>Order</h1>
        <div className={styles.siblingOnceRemovedNodes} />
      </div>
      <div className={styles.uncleOnceRemovedNodes}>
        <div className={styles.auntOnceRemovedNodes}>
          <div className={styles.cousinOnceRemovedTwiceNode}>
            <h2 className={styles.h2}>{t('order_list')}</h2>
            <div className={styles.descendantTwiceRemovedNodes}>
              <div className={styles.siblingTwiceRemovedNodes} />
              <div className={styles.uncleTwiceRemovedNodes}>
                <div className={styles.auntTwiceRemovedNodes}>
                  {cartItems.map(item => (
                      <HeaderRowCell
                          key={item.cartItemId}
                          thumb={item.imageUrl} // 예시 이미지 경로
                          heading4TheSpraySet={item.name}
                          heading4TheSpraySet1={`₩ ${item.salePrice.toLocaleString()}`}
                      />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.descendantFourTimesRemoved}>
            <div className={styles.siblingFourTimesRemovedNod}>
              <div className={styles.uncleFourTimesRemovedNodes}>
                <div className={styles.auntFourTimesRemovedNodes}>
                  <h2 className={styles.h21}>{t('addr_info')}</h2>
                  <div className={styles.ancestorFiveTimesRemovedNo} />
                </div>
              </div>
              <div className={styles.descendantFiveTimesRemoved}>
                {/*<div className={styles.siblingFiveTimesRemovedNod}>*/}
                {/*  <div className={styles.uncleFiveTimesRemovedNodes}>*/}
                {/*    <div className={styles.auntFiveTimesRemovedNodes}>*/}
                {/*      <div className={styles.cousinFiveTimesRemovedNode}>*/}
                {/*        <div className={styles.ancestorSixTimesRemovedNod} />*/}
                {/*      </div>*/}
                {/*      <div className={styles.div}>회원 정보와 동일</div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.auntFiveTimesRemovedNodes1}>*/}
                {/*      <div className={styles.auntFiveTimesRemovedNodesInner}>*/}
                {/*        <div className={styles.frameChild} />*/}
                {/*      </div>*/}
                {/*      <div className={styles.div1}>새로운 배송지</div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className={styles.siblingSixTimesRemovedNode}>
                  <div className={styles.uncleSixTimesRemovedNodes}>

                    <label className={styles.div2}>{t('name')}
                      <div className={styles.loopControl}>
                        <input type="text" className={styles.conditionChecker} name="fieldName" value={name} onChange={(e) => setName(e.target.value)}/>
                      </div>
                    </label>
                  </div>
                  <div className={styles.uncleSixTimesRemovedNodes1}>
                    <div className={styles.parent}>
                      <div className={styles.addressSearchSection}>
                        <button type="button" onClick={() => setPopup(true)} className={styles.outputHandler}>
                          <div className={styles.outputHandlerChild}></div>
                          <div className={styles.heading4}>{t('addr_search')}</div>
                        </button>
                        {popup && (
                            <div className={styles.postPopup}>
                              <DaumPost onComplete={handleAddress} />
                            </div>
                        )}
                        <div className={styles.addressInfo}>
                          <label className={styles.addressLabel}>{t('postcode')}
                            <div className={styles.loopControl}>
                              <input type="text" value={zonecode} readOnly className={styles.conditionChecker} />
                            </div>
                          </label>
                          <label className={styles.addressLabel}>{t('addr')}
                            <div className={styles.loopControl}>
                              <input type="text" value={address} readOnly className={styles.conditionChecker} />
                            </div>
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className={styles.uncleSixTimesRemovedNodes3}>
                    <label className={styles.div5}>{t('addr_detail')}  ({t('optional')})
                      <div className={styles.rectangleContainer}>
                        <input type="text" value={detailAddress} onChange={(e) => setDetailAddress(e.target.value)} className={styles.frameInner} name="detailAddress" />
                      </div>
                    </label>
                  </div>
                  <div className={styles.functionCaller}>
                    <label className={styles.div6}>{t('phone')}
                      <div className={styles.rectangleContainer}>
                        <input type="text" className={styles.frameInner} name="phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                      </div>
                    </label>
                  </div>
                  <div className={styles.functionCaller}>
                    <label className={styles.div7}>{t('email')}
                      <div className={styles.rectangleContainer}>
                        <input type="text" className={styles.frameInner} name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </label>
                  </div>
                  <div className={styles.dataSharer}>
                    <label className={styles.div8}>{t('ship_request')}
                      <div className={styles.dataLinker}>
                        <div className={styles.dataAnalyzer}>
                          <input type="text" className={styles.dataVisualizer1} name="deliveryNote" value={deliveryNote} onChange={(e) => setDeliveryNote(e.target.value)} />
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className={styles.dataStorage}>*/}
            {/*  <div className={styles.dataRetriever}>*/}
            {/*    <h2 className={styles.h22}>할인</h2>*/}
            {/*    <div className={styles.dataExpander}>*/}
            {/*      <div className={styles.dataEncoder} />*/}
            {/*      <div className={styles.dataDecoder}>*/}
            {/*        <div className={styles.dataEncryptor}>*/}
            {/*          <div className={styles.div9}>적립금</div>*/}
            {/*          <div className={styles.dataValidator}>*/}
            {/*            <div className={styles.dataVerifier}>*/}
            {/*              <img*/}
            {/*                className={styles.dataVerifierChild}*/}
            {/*                loading="lazy"*/}
            {/*                alt=""*/}
            {/*                src="/rectangle-22.svg"*/}
            {/*              />*/}
            {/*              <div className={styles.dataSecurity}>*/}
            {/*                <button className={styles.dataBackup}>*/}
            {/*                  <div className={styles.dataBackupChild} />*/}
            {/*                  <div className={styles.heading41}>적용</div>*/}
            {/*                </button>*/}
            {/*                <div className={styles.dataTransfer}>*/}
            {/*                  <div className={styles.div10}>*/}
            {/*                    보유잔액 5000원*/}
            {/*                  </div>*/}
            {/*                </div>*/}
            {/*              </div>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <div className={styles.dataQueue}>*/}
            {/*          <div className={styles.div11}>*/}
            {/*            <span>*/}
            {/*              <p className={styles.p}>*/}
            {/*                1회 구매시 최대 사용금액은 5,000원입니다.*/}
            {/*              </p>*/}
            {/*              <p className={styles.p1}>*/}
            {/*                최대 사용금액은 제한이 없습니다.*/}
            {/*              </p>*/}
            {/*              <p className={styles.p2}>*/}
            {/*                최소 적립금 0원 이상일 때 사용 가능합니다.*/}
            {/*              </p>*/}
            {/*            </span>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className={styles.dataHeap}>*/}
            {/*      <div className={styles.dataPool} />*/}
            {/*      <div className={styles.dataStream}>*/}
            {/*        <div className={styles.div12}>적용금액</div>*/}
            {/*        <div className={styles.dataMatrix}>*/}
            {/*          <div className={styles.heading42}>- ₩ 0</div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={styles.conditionSplitter}>
              <div className={styles.valueFilter}>
                <div className={styles.textParser}>
                  <h2 className={styles.h23}>{t('pay_method')}</h2>
                  <div className={styles.dateHandler}>
                    <label className={styles.imageProcessor}>
                      <input
                          type="radio"
                          name="paymentMethod"
                          value="creditCard"
                          className={styles.geometryTransform}
                          onChange={() => setPaymentMethod('creditCard')}
                          checked={paymentMethod === 'creditCard'}
                      /><div className={styles.functionNode}>{t('card')}</div>
                    </label>
                    <label className={styles.imageProcessor}>
                      <input
                          type="radio"
                          name="paymentMethod"
                          value="virtualAccount"
                          className={styles.geometryTransform}
                          onChange={() => setPaymentMethod('virtualAccount')}
                          checked={paymentMethod === 'virtualAccount'}
                      /><div className={styles.functionNode}>{t('account')}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.matrixNode}>
              <div className={styles.arrayNode}>
                <div className={styles.dictionaryNode}>
                  <h2 className={styles.h24}>{t('pay_cost')}</h2>
                  <div className={styles.stackNode} />
                </div>
                <div className={styles.queueNode}>
                  <div className={styles.priorityQueueNode}>
                    <div className={styles.heapNode}>
                      <div className={styles.div17}>{t('order_cost')}</div>
                      <div className={styles.div18}>{t('shipping_cost')}</div>
                      <div className={styles.hashTableNode}>
                        <div className={styles.div19}>{t('discount')}</div>
                      </div>
                    </div>
                    <div className={styles.treeNode}>
                      <div className={styles.forestNode}>
                        <div className={styles.heading43}>+ ₩ {itemsTotalPrice}</div>
                        <div className={styles.heading44}>+ ₩ {shippingCost}</div>
                        <div className={styles.heading45}>- ₩ 0</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.clusterNode} />
                </div>
                <div className={styles.componentNode}>
                  <div className={styles.div20}>{t('total_cost')}</div>
                  <div className={styles.vertexNode}>
                    <div className={styles.heading46}>₩ {totalPrice}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.cycleDetector}>
              <button className={styles.shortestPathNode} onClick={handlePayment}>
                <div
                  className={styles.dijkstraNode}
                  onClick={handlePayment}
                />
                <div className={styles.div21}>{t('Pay')}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CousinOnceRemovedNodes;
