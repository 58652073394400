import {useMemo, useState} from "react";
import styles from "./CollapsibleNode.module.css";
import {useTranslation} from "react-i18next";

const CollapsibleNode = ({ propPadding, addToCart, submitOrder }) => {
  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const [variableValue, setVariableValue] = useState(1);
  const [cost, setCost] = useState(36900);

  const collapsibleNodeStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  // '-' 버튼 클릭 핸들러
  const handleMinusClick = () => {
    setVariableValue(prevValue => Math.max(prevValue - 1, 1)); // 값이 0보다 작아지지 않도록
    setCost(prevValue => prevValue - 36900);
  };

  // '+' 버튼 클릭 핸들러
  const handlePlusClick = () => {
    setVariableValue(prevValue => prevValue + 1);
    setCost(prevValue => prevValue + 36900);
  };

  return (
    <div className={styles.collapsibleNode} style={collapsibleNodeStyle}>
      <div className={styles.filterNode}>
        <div className={styles.transformerNode}>
          <div className={styles.aggregatorNode}>
            <h3 className={styles.h3}>{t('quantity')}</h3>
            <div className={styles.joinerNode}>
              {/* '-' 버튼 */}
              <div className={styles.normalizerNode} onClick={handleMinusClick}>
                <div className={styles.scalerNode} />
                <h3 className={styles.thresholdNode}>-</h3>
              </div>
              {/* 변수 값 표시 */}
              <div className={styles.ruleSetNode}>{variableValue}</div>
              {/* '+' 버튼 */}
              <div className={styles.normalizerNode1} onClick={handlePlusClick}>
                <div className={styles.normalizerNodeChild} />
                <h3 className={styles.h31}>+</h3>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.conditionListNode}>
          <div className={styles.trueBranchNode}>
            <div className={styles.falseBranchNode}>
              <h1 className={styles.total}>Total</h1>
              <div className={styles.strong0}>₩{cost}</div>
            </div>
          </div>
          <div className={styles.constantNode}>
            <button className={styles.cart} onClick={addToCart}>
              <div className={styles.cart1}>Cart</div>
            </button>
            <div className={styles.matrixNode}>
              <button className={styles.buy} onClick={submitOrder}>
                <div className={styles.buy1}>Buy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsibleNode;
