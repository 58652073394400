import React, { useState, useEffect } from 'react';
import axios from 'axios'; // HTTP 요청을 위해 axios 사용

function UserProfile() {
    // 사용자 정보를 저장할 상태
    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        phone: '',
        // 추가적으로 필요한 정보들을 여기에 정의할 수 있습니다.
    });

    const [adrInfo, setAdrInfo] = useState({
        address: '',
        addressDetail: '',
        zipcode: ''
    });

    // 컴포넌트가 마운트될 때 사용자 정보를 가져오는 함수
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch('/members/info', {
                    credentials: 'include'  // 쿠키를 요청에 포함시키기 위해 설정
                });
                if (!response.ok) {
                    throw new Error('네트워크 응답이 올바르지 않습니다.');
                }
                const data = await response.json();  // 응답을 JSON으로 변환
                console.log(data);
                setUserInfo(data.member);  // 가져온 사용자 정보를 상태에 저장
                setAdrInfo(data.address);
            } catch (error) {
                console.error('사용자 정보를 가져오는 데 실패했습니다.', error);
            }
        };

        fetchUserInfo();
    }, []); // 의존성 배열을 빈 배열로 설정하여 컴포넌트 마운트 시 한 번만 실행되도록 함

    return (
        <div>
            <h2>마이 페이지</h2>
            <div>
                <p>이름: {userInfo.username}</p>
                <p>이메일: {userInfo.email}</p>
                <p>전화번호: {userInfo.phone}</p>
                <p>우편번호: {adrInfo.zipcode}</p>
                <p>주소: {adrInfo.address}</p>
                <p>상세주소: {adrInfo.addressDetail}</p>
            </div>
        </div>
    );
}

export default UserProfile;
