import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function ItemUpdate() {
    const { itemId } = useParams();
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');

    // 기존 공지사항 정보 로드
    useEffect(() => {
        axios.get(`/item/${itemId}`)
            .then(response => {
                setName(response.data.name);
                setPrice(response.data.price);
                setStock(response.data.stock);
            })
            .catch(error => console.error(error));
    }, [itemId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`/item/${itemId}`, { name, price, stock })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => console.error(error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" value={name} onChange={e => setName(e.target.value)} />
            </label>
            <br />
            <label>
                Price:
                <textarea value={price} onChange={e => setPrice(e.target.value)} />
            </label>
            <br />
            <label>
                Stock:
                <textarea value={stock} onChange={e => setStock(e.target.value)} />
            </label>
            <br />
            <button type="submit">Update</button>
        </form>
    );
}

export default ItemUpdate;
