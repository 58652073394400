import React, {useEffect, useState} from 'react';
import '../../css/Main.css';
import axios from "axios";
import Cookies from "js-cookie";

function Main() {
    const [userInfo, setUserInfo] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await fetch('/members/auth/status', {
                    credentials: 'include'  // 쿠키를 요청에 포함시키기 위해 설정
                });
                if (response.ok) {
                    const data = await response.json();
                    setIsLoggedIn(data.isLoggedIn);
                    setUserInfo(data);
                    console.log(data);
                } else if (response.status === 401) {
                    // 토큰 만료 또는 유효하지 않음 처리
                    alert("Session expired. Please login again.");
                    setIsLoggedIn(false);
                    // 필요한 경우 로그인 페이지로 리다이렉트
                }
            } catch (error) {
                console.error('로그인 상태 확인 중 오류 발생:', error);
            }
        };
        checkLoginStatus();
    }, []);


    // 로그아웃 함수
    const handleLogout = () => {
        fetch('members/auth/logout', {
            method: 'POST',
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                console.log(data.message);
                // 클라이언트 상태 업데이트 (예: 로그인 상태 변경)
                setIsLoggedIn(false);
                // 필요하다면 여기서 사용자를 로그인 페이지로 리다이렉트
            })
            .catch(error => console.error('Logout error:', error));

    };

    if (!isLoggedIn) {
        return (
            <div>
                <h1>Loading..</h1>
                <button onClick={handleLogout}>Logout</button>
            </div>
        );
    }


    return (
        <div>
            <h1>Welcome, {userInfo.username}!</h1>
            {/* 사용자 정보를 활용한 UI 구성 */}
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default Main;