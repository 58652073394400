import React, {useCallback, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent4 from "./FrameComponent4";
import styles from "./FrameComponent3.module.css";
import DaumPost from "../pages/Login/DaumPost";
import {useTranslation} from "react-i18next";

const FrameComponent3 = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  // 입력 상태를 관리하기 위한 useState 훅
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [email, setEmail] = useState('');
  const [birthDate, setBirthDate] = useState('');

  const [phoneFirst, setPhoneFirst] = useState('010');
  const [phoneMiddle, setPhoneMiddle] = useState('');
  const [phoneLast, setPhoneLast] = useState('');

  const [popup, setPopup] = useState(false);
  const [address, setAddress] = useState('');
  const [zonecode, setZonecode] = useState(''); // 우편번호 상태

  // 입력 필드 변경 핸들러
  const handleInputChange = (event, setState) => {
    setState(event.target.value);
  };

  const handleAddress = (data) => {
    // DaumPost에서 주소 선택 시 호출될 함수
    console.log(data)
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
      }
      fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
    }

    setAddress(fullAddress); // 선택한 주소를 상태에 저장
    setZonecode(data.zonecode); // 우편번호를 상태에 저장
    setPopup(false); // 주소 검색 팝업 닫기
  };

  const handleSubmit = async () => {
    const userData = {
      username: name,
      email: email,
      password: password,
      phone: `${phoneFirst}${phoneMiddle}${phoneLast}`
    };

    try {
      const response = await fetch('/members/auth/signUp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      const data = await response.json();  // 서버로부터 응답을 JSON 형태로 받기
      if (response.ok) {
        console.log('User registered successfully:', data);
        // 회원 가입 성공 후 처리, 예: 로그인 페이지로 리다이렉트
        navigate('/login');
      } else {
        throw new Error('Failed to register user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  return (
      <div className={styles.siblingNodes}>
        <header className={styles.childNodes}>
          <FrameComponent4
              onHeaderList3Click={onHeaderList3Click}
              onHeaderListClick={onHeaderListClick}
              onHeaderList1Click={onHeaderList1Click}
              onHeaderListItemLinkClick={onHeaderListItemLinkClick}
              onHeaderList4Click={onHeaderList4Click}
          />
        </header>
        <div className={styles.cousinOnceRemovedNodes}>
          <h1 className={styles.join}>Join</h1>
          <div className={styles.descendantOnceRemovedNodes}>
            <div className={styles.siblingOnceRemovedNodes}>
              <input
                  className={styles.tableBodyRowDataIn4} // 예시 클래스, 실제 입력 필드에 맞게 조정해주세요
                  placeholder={t('email')}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.siblingOnceRemovedNodes1}>
              <input
                  className={styles.tableBodyRowDataIn4} // 예시 클래스
                  placeholder={t('password')}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className={styles.descendantTwiceRemovedNodes}>
              <div className={styles.tableBody1}>
                {t('need')}
              </div>
            </div>
            <div className={styles.siblingOnceRemovedNodes2}>
              <input
                  className={styles.tableBodyRowDataIn4} // 예시 클래스
                  placeholder={t('confirm')}
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className={styles.siblingOnceRemovedNodes3}>
              <input
                  className={styles.tableBodyRowDataIn4} // 예시 클래스
                  placeholder={t('name')}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={styles.uncleTwiceRemovedNodes}>
              <div>
                <input
                    type="text"
                    className={styles.tableBodyRowDataIn4}
                    value={zonecode}
                    placeholder={t('postcode')}
                    readOnly
                    onChange={(e) => setZonecode(e.target.value)}
                />
                <div className={styles.tableBodyRowDataLi}>
                  <button type="button" className={styles.div4} onClick={() => setPopup(true)}>{t('addr_search')}</button>
                  {popup && <DaumPost onComplete={handleAddress} />}
                </div>
                <input
                    type="text"
                    value={address}
                    readOnly
                    className={styles.tableBodyRowDataIn4}
                    placeholder={t('addr')}
                    onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <input
                  className={styles.tableBodyRowDataIn4} // 예시 클래스
                  placeholder={t('addr_detail')}
                  type="text"
                  value={detailAddress}
                  onChange={(e) => setDetailAddress(e.target.value)}
              />

              <div className={styles.tableBody2}>{t('optional')}</div>
              <div className={styles.ancestorFourTimesRemovedNo}>
                <div className={styles.tableBodyRowDataOp1}>
                  <div className={styles.div7}>010</div>
                  <div className={styles.tableBodyRowDataOpInner}>
                    <div className={styles.frameParent}>
                      <div className={styles.tableBody5}>-</div>
                    </div>
                  </div>
                </div>
                <input
                    className={styles.tableBodyRowDataIn16}
                    type="text"
                    value={phoneMiddle}
                    onChange={(e) => setPhoneMiddle(e.target.value)}
                />

                <div className={styles.tableBody6}>-</div>
                <input
                    className={styles.tableBodyRowDataIn16}
                    type="text"
                    value={phoneLast}
                    onChange={(e) => setPhoneLast(e.target.value)}
                />
              </div>
            </div>

          </div>
        </div>
        <div className={styles.siblingSixTimesRemovedNode}>
          <div className={styles.divtitlearea}>
            <h3 className={styles.h3}>{t('additional_info')}</h3>
          </div>
          <div className={styles.cousinSixTimesRemovedNodes}>
            <div className={styles.dataAggregator}>
              <div className={styles.tableBody10}>{t('birth_date')}</div>
              <div className={styles.imageHolder}>
                <div className={styles.progressBarSet}>
                  <input
                      className={styles.tableBodyRowDataIn16}
                      placeholder={t('year')}
                      type="text"
                  />
                  <input
                      className={styles.tableBodyRowDataIn16}
                      placeholder={t('month')}
                      type="text"
                  />
                  <input
                      className={styles.tableBodyRowDataIn16}
                      placeholder={t('day')}
                      type="text"
                  />
                </div>
              </div>
            </div>
            <div className={styles.link}>
              <button className={styles.div9} onClick={handleSubmit}>{t('done_join')}</button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FrameComponent3;
