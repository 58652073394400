import { useCallback } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Footer from "../../components/Footer";
import styles from "./OrderDone.module.css";
import {useTranslation} from "react-i18next";

const OrderDone = () => {
  const location = useLocation();
  const { orderId } = location.state || {}; // state가 없는 경우를 대비한 기본값 설정

  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onGroupButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={styles.orderdone}>
      <section className={styles.orderdoneInner}>
        <div className={styles.frameParent}>
          <div className={styles.wrapper}>
            <img
              className={styles.icon}
              loading="lazy"
              alt=""
              src="/-1-1@2x.png"
              onClick={onImageClick}
            />
          </div>
          <div className={styles.frameGroup}>
            <header className={styles.frameWrapper}>
              <div className={styles.loginFrameParent}>
                <div className={styles.loginFrame}>
                  <h1
                    className={styles.headerList}
                    onClick={onHeaderList3Click}
                  >
                    Shop
                  </h1>
                  <h1
                    className={styles.headerList1}
                    onClick={onHeaderListClick}
                  >
                    Brand
                  </h1>
                  <div className={styles.headerList2}>B2B</div>
                  <h1
                    className={styles.headerList3}
                    onClick={onHeaderList1Click}
                  >
                    CS
                  </h1>
                  <div className={styles.messageFrame}>
                    <img
                      className={styles.instagram1Icon}
                      loading="lazy"
                      alt=""
                      src="/instagram-1.svg"
                    />
                  </div>
                </div>
                <div className={styles.helpFrame}>
                  <img
                    className={styles.headerListItemLink}
                    loading="lazy"
                    alt=""
                    src="/header--list--item--link--login-whitesvg.svg"
                    onClick={onHeaderListItemLinkClick}
                  />
                  <h1
                    className={styles.headerList4}
                    onClick={onHeaderList4Click}
                  >
                    Cart
                  </h1>
                </div>
              </div>
            </header>
            <div className={styles.orderCompletedFrame}>
              <h1 className={styles.h1}>{t('done')}</h1>
              <div className={styles.confirmationGroupFrame}>
                <div className={styles.div}>{t('order_num')} {orderId}</div>
              </div>
              <div className={styles.helpListFrame}>
                <button
                  className={styles.rectangleParent}
                  onClick={onGroupButtonClick}
                >
                  <div className={styles.frameChild} />
                  <div className={styles.div1}>{t('ok')}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer
        footerPadding="var(--padding-138xl) var(--padding-5xl) 25.400000000001455px"
        onListItem4Click={onListItem4Click}
        onListItem5Click={onListItem5Click}
        onListItem1Click={onListItem1Click}
      />
    </div>
  );
};

export default OrderDone;
