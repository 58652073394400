import React, { useState, useEffect } from 'react';

function OrderHistory() {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await fetch('/myOrder', {
                method: 'GET',
                credentials: 'include', // 현재 사용자의 인증 정보를 포함시킵니다.
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('주문 정보를 불러오는 데 실패했습니다.');
            }

            const data = await response.json();
            setOrders(data.orders);
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <h2>My Orders</h2>
            {orders.length > 0 ? (
                <div>
                    {orders.map((order) => (
                        <div key={order.id} className="order">
                            <h3>Order ID: {order.id}</h3>
                            <p>Order Date: {order.orderDate}</p>
                            <p>Total Price: {order.totalPrice}</p>
                            <h4>Items:</h4>
                            <ul>
                                {order.orderItemDtoList.map((item) => (
                                    <li key={item.id}>
                                        {item.itemName} - Quantity: {item.count} - price: {item.orderPrice}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No orders found.</p>
            )}
        </div>
    );
}

export default OrderHistory;
