import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CartFrame from "../../components/CartFrame";
import CousinOnceRemovedNodes from "../../components/CousinOnceRemovedNodes";
import Footer7 from "../../components/Footer7";
import styles from "./Order.module.css";

const Order = () => {
  const navigate = useNavigate();

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  return (
    <div className={styles.order}>
      <main className={styles.divwrap}>
        <CartFrame
          onImageClick={onImageClick}
          onHeaderList3Click={onHeaderList3Click}
          onHeaderListClick={onHeaderListClick}
          onHeaderList1Click={onHeaderList1Click}
          onHeaderListItemLinkClick={onHeaderListItemLinkClick}
          onHeaderList4Click={onHeaderList4Click}
        />
        <section className={styles.nephewNodes}>
          <CousinOnceRemovedNodes />
        </section>
        <Footer7
          onListItem4Click={onListItem4Click}
          onListItem5Click={onListItem5Click}
          onListItem1Click={onListItem1Click}
        />
      </main>
    </div>
  );
};

export default Order;
