import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent1 from "../../components/FrameComponent1";
import FrameComponent from "../../components/FrameComponent";
import Footer from "../../components/Footer";
import styles from "./MainPage.module.css";
import {useTranslation} from "react-i18next";

const MainPage = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onGroupContainerClick = useCallback(() => {
    navigate("/product-pageserum");
  }, [navigate]);

  const onGroupContainer1Click = useCallback(() => {
    navigate("/product-pagemist");
  }, [navigate]);

  return (
    <div className={styles.mainPage}>
      <section className={styles.frameParent}>
        <FrameComponent1 />
        <div className={styles.frameWrapper}>
          <div className={styles.sectionListItemLinkParent}>
            <h1 className={styles.sectionList}>Best</h1>
            <div className={styles.frameGroup}>
              <div
                className={styles.sectionListItemLinkGroup}
                onClick={onGroupContainerClick}
              >
                <img
                  className={styles.sectionListItemLink}
                  loading="lazy"
                  alt=""
                  src="/thumbSerum.jpg"
                />
                <div className={styles.frameWrapper2}>
                  <div className={styles.frameParent2}>
                    <div className={styles.sectionListItemLinkFrame}>
                      <h3 className={styles.sectionList2}>Body Serum</h3>
                    </div>
                    <div className={styles.sectionListItemLinkParent1}>
                      <div className={styles.sectionListItemLink2}>
                        <div className={styles.ml1}>
                          {t('serum')}
                        </div>
                      </div>
                      <div className={styles.group}>
                        <div className={styles.div2}>₩ 28,000</div>
                        <div className={styles.container}>
                          <div className={styles.div3}>₩ 19,900</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={styles.sectionListItemLinkContainer}
                onClick={onGroupContainer1Click}
              >
                <img
                  className={styles.sectionListItemLink1}
                  loading="lazy"
                  alt=""
                  src="/thumbMist.jpg"
                />
                <div className={styles.frameWrapper2}>
                  <div className={styles.frameParent2}>
                    <div className={styles.sectionListItemLinkFrame}>
                      <h3 className={styles.sectionList2}>Body Mist</h3>
                    </div>
                    <div className={styles.sectionListItemLinkParent1}>
                      <div className={styles.sectionListItemLink2}>
                        <div className={styles.ml1}>
                          {t('mist')}
                        </div>
                      </div>
                      <div className={styles.group}>
                        <div className={styles.div2}>₩ 27,000</div>
                        <div className={styles.container}>
                          <div className={styles.div3}>₩ 17,900</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FrameComponent />
      <Footer
        onListItem4Click={onListItem4Click}
        onListItem5Click={onListItem5Click}
        onListItem1Click={onListItem1Click}
      />
    </div>
  );
};

export default MainPage;
