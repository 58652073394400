import styles from "./Menu.module.css";

const Menu = ({
  onHeaderListClick,
  onHeaderList1Click,
  onHeaderList3Click,
  onHeaderListItemLinkClick,
  onHeaderList4Click,
  onImageClick,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerList} onClick={onHeaderListClick}>
        Brand
      </div>
      <div className={styles.headerList1} onClick={onHeaderList1Click}>
        CS
      </div>
      <div className={styles.headerList3} onClick={onHeaderList3Click}>
        Shop
      </div>
      <img
        className={styles.headerListItemLink}
        alt=""
        src="/iconLogin.svg"
        onClick={onHeaderListItemLinkClick}
      />
      <div className={styles.headerList4} onClick={onHeaderList4Click}>
        Cart
      </div>
      <img className={styles.instagram1Icon} alt="" src="/instagram.png" />
      <img
        className={styles.icon}
        alt=""
        src="/logoDarkgreen.png"
        onClick={onImageClick}
      />
    </div>
  );
};

export default Menu;
