import { useMemo } from "react";
import styles from "./PrdDetail.module.css";

const PrdDetail = ({ propPadding, propPadding1 }) => {
  const itemLinkStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const itemLink1Style = useMemo(() => {
    return {
      padding: propPadding1,
    };
  }, [propPadding1]);

  return (
      <section className={styles.prddetail}>
        <div className={styles.clusterNode}>
          <div className={styles.outlierNode}>
            <button className={styles.itemLink}>
              <div className={styles.detail}>Detail</div>
            </button>
            <button className={styles.itemLink1}>
              <div className={styles.review}>Review</div>
            </button>
          </div>
        </div>
        <img
            className={styles.icon}
            loading="lazy"
            alt=""
            src="/detailMist.jpeg"
        />
      </section>
  );
};

export default PrdDetail;
