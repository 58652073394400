import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ShopHeader from "../../components/ShopHeader";
import styles from "./BrandStory2.module.css";
import {useTranslation} from "react-i18next";

const BrandStory2 = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onDivIconClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onDivClick = useCallback(() => {
    navigate("/brandstory3");
  }, [navigate]);

  const onDiv1Click = useCallback(() => {
    navigate("/brandstory4");
  }, [navigate]);

  return (
    <div className={styles.brandstory2}>
      <div className={styles.divhinokAboutWrap} />
      <main className={styles.divswiperSlide}>
        <section className={styles.frameParent}>
          <div className={styles.waterBrandParent}>
            <div className={styles.waterBrand}>
              <img
                className={styles.icon}
                loading="lazy"
                alt=""
                src="/logoWhite.png"
                onClick={onImageClick}
              />
            </div>
            <div className={styles.frameGroup}>
              <div className={styles.frameWrapper}>
                <ShopHeader
                  onHeaderList3Click={onHeaderList3Click}
                  onHeaderListClick={onHeaderListClick}
                  onHeaderList1Click={onHeaderList1Click}
                  onHeaderListItemLinkClick={onHeaderListItemLinkClick}
                  onHeaderList4Click={onHeaderList4Click}
                />
              </div>
              <div className={styles.textParent}>
                <div className={styles.text}>
                  <div className={styles.mineralWater}>{`Mineral water `}</div>
                </div>
                <div className={styles.text1}>
                  <div className={styles.onYang}>
                    <p className={styles.p}>
                      {t('brand2-1')}
                    </p>
                    <p
                      className={styles.p1}
                    >{t('brand2-2')}</p>
                    <p className={styles.p2}>
                      {t('brand2-3')}
                    </p>
                    <p className={styles.onYang1}>{t('brand2-4')}</p>
                  </div>
                  <div className={styles.div}>
                    <span className={styles.txt}>
                      <p
                        className={styles.p3}
                      >{t('brand2-5')}</p>
                      <p className={styles.p4}>
                        {t('brand2-6')}
                      </p>
                    </span>
                  </div>
                </div>
                <div className={styles.div1}>
                  <span className={styles.txt1}>
                    <p
                      className={styles.p5}
                    >{t('brand2-7')}</p>
                    <p className={styles.p6}>
                      {t('brand2-8')}
                    </p>
                    <p className={styles.p7}>
                      {t('brand2-9')}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.text2}>
            <h2 className={styles.origin}>Origin</h2>
          </div>
        </section>
        <div className={styles.divActiveWrapper}>
          <div className={styles.divActive}>
            <img
              className={styles.divIcon}
              loading="lazy"
              alt=""
              src="/div.svg"
              onClick={onDivIconClick}
            />
            <div className={styles.divactive} />
            <div className={styles.div2} onClick={onDivClick} />
            <div className={styles.div3} onClick={onDiv1Click} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrandStory2;
