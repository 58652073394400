import {useCallback, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import WelcomeContainer from "../../components/WelcomeContainer";
import Menu from "../../components/Menu";
import Footerr from "../../components/Footerr";
import styles from "./CSMyPageOrder.module.css";
import Footer from "../../components/Footer";
import axios from "axios";
import Cookies from "js-cookie";

const CSMyPageOrder = () => {
  const navigate = useNavigate();
  let name = "";

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItemClick = useCallback(() => {
    navigate("/csmy-page-profile");
  }, [navigate]);

  const onListItem12Click = useCallback(() => {
    navigate("/csqnawrite");
  }, [navigate]);

  const onListItem13Click = useCallback(() => {
    navigate("/csmy-page-order-2");
  }, [navigate]);


  return (
    <div className={styles.csMyPageOrder}>
      <div className={styles.category}>
        <div className={styles.listItem} onClick={onListItemClick}>
          Profile
        </div>
        <div
          className={styles.listItem1}
          onClick={onListItem12Click}
        >{`1:1 Q&A`}</div>
        <div className={styles.listItem2}>Order</div>
        <div className={styles.myPage}>My Page</div>
      </div>
      <div className={styles.csMyPageOrderChild} />
      <WelcomeContainer />
      <div className={styles.listItem3}>주문 조회</div>
      <div className={styles.listItem4} onClick={onListItem13Click}>
        교환/반품/취소
      </div>
      <div className={styles.listItem5}>주문번호</div>
      {/*<div className={styles.listItem6}>42345234634256534</div>*/}
      {/*<div className={styles.div}>*/}
      {/*  <div className={styles.headerRowCell}>*/}
      {/*    <div className={styles.headerRowCellChild} />*/}
      {/*    <div className={styles.listItem7}>취소하기</div>*/}
      {/*    <div className={styles.headerRowCellItem} />*/}
      {/*    <div className={styles.listItem8}>배송조회</div>*/}
      {/*    <img className={styles.thumbIcon} alt="" src="/thumb@2x.png" />*/}
      {/*    <div className={styles.heading4}>[Set] Body Mist 2-Piece Set</div>*/}
      {/*    <div className={styles.heading41}>₩ 34,900</div>*/}
      {/*    <div className={styles.div1}>수량: 1개</div>*/}
      {/*    <div className={styles.listItem9}>배송준비중</div>*/}
      {/*  </div>*/}
      {/*  <div className={styles.headerRowCell1}>*/}
      {/*    <div className={styles.heading42}>Body Mist</div>*/}
      {/*    <div className={styles.heading43}>₩ 27,000</div>*/}
      {/*    <div className={styles.div2}>수량: 1개</div>*/}
      {/*    <img className={styles.thumbIcon1} alt="" src="/thumb@2x.png" />*/}
      {/*    <div className={styles.listItem10}>배송준비중</div>*/}
      {/*    <div className={styles.headerRowCellInner} />*/}
      {/*    <div className={styles.listItem11}>취소하기</div>*/}
      {/*    <div className={styles.rectangleDiv} />*/}
      {/*    <div className={styles.listItem12}>배송조회</div>*/}
      {/*  </div>*/}
      {/*  <div className={styles.child} />*/}
      {/*</div>*/}
      <img className={styles.csMyPageOrderItem} alt="" src="/line-10.svg" />
      <Menu
        onHeaderListClick={onHeaderListClick}
        onHeaderList1Click={onHeaderList1Click}
        onHeaderList3Click={onHeaderList3Click}
        onHeaderListItemLinkClick={onHeaderListItemLinkClick}
        onHeaderList4Click={onHeaderList4Click}
        onImageClick={onImageClick}
      />
      <Footerr
      />
    </div>
  );
};

export default CSMyPageOrder;
