import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CartFrame.module.css";

const CartFrame = () => {
  const navigate = useNavigate();

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <header className={styles.cartFrame}>
      <div className={styles.headerFrame}>
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoDarkgreen.png"
          onClick={onImageClick}
        />
        <div className={styles.instagramFrameParent}>
          <div className={styles.instagramFrame}>
            <div className={styles.headerList} onClick={onHeaderList3Click}>
              Shop
            </div>
            <div className={styles.headerList1} onClick={onHeaderListClick}>
              Brand
            </div>
            <div className={styles.headerList2}>B2B</div>
            <div className={styles.headerList3} onClick={onHeaderList1Click}>
              CS
            </div>
            <div className={styles.cartLinkFrame}>
              <img
                className={styles.instagram1Icon}
                loading="lazy"
                alt=""
                src="/instagram.png"
              />
            </div>
          </div>
          <div className={styles.thumbnailFrame}>
            <img
              className={styles.headerListItemLink}
              loading="lazy"
              alt=""
              src="/iconLogin.svg"
              onClick={onHeaderListItemLinkClick}
            />
            <div className={styles.headerList4} onClick={onHeaderList4Click}>
              Cart
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default CartFrame;
