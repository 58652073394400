import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.css";
import {useAuth} from "./AuthContext";

const Header = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useAuth();

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  return (
    <header className={styles.header}>
      <nav className={styles.listContainer}>
        <div className={styles.itemFrame}>
          <h3 className={styles.headerList} onClick={onHeaderList3Click}>
            Shop
          </h3>
        </div>
        <div className={styles.itemFrame1}>
          <h3 className={styles.headerList1} onClick={onHeaderListClick}>
            Brand
          </h3>
        </div>
        {/*<div className={styles.headerList2}>B2B</div>*/}
        <h3 className={styles.headerList3} onClick={onHeaderList1Click}>
          CS
        </h3>
        <img
            className={styles.instagram2Icon}
            loading="lazy"
            alt=""
            src="/instagram.png"
        />
      </nav>
      <img
          className={styles.headerListItemLink}
          loading="lazy"
          alt=""
          src="/iconLogin.svg"
          onClick={onHeaderListItemLinkClick}
      />
      <h3 className={styles.headerList4} onClick={onHeaderList4Click}>
        Cart
      </h3>

      <div className={styles.productImageFrame}>
        <img
          className={styles.divxansProductIcon}
          alt=""
          src="/productTop.jpg"
        />
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/-2-1@2x.png"
          onClick={onImageClick}
        />


      </div>
    </header>
  );
};

export default Header;
