import { useMemo } from "react";
import styles from "./HelpTextFrame.module.css";

const HelpTextFrame = ({
  ml195ml,
  setBodySerumBodyMistSet,
  prop,
  propPadding,
  onDivitemDescriptionContainerClick,
}) => {
  const helpTextFrameStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  return (
    <div className={styles.helpTextFrame} style={helpTextFrameStyle}>
      <div
        className={styles.divitemDescription}
        onClick={onDivitemDescriptionContainerClick}
      >
        <div className={styles.sectionListContainer}>
          <span className={styles.sectionListContainer1}>
            <p className={styles.ml}>{ml195ml}</p>
          </span>
        </div>
        <div className={styles.setBodySerum}>{setBodySerumBodyMistSet}</div>
        <div className={styles.chatItemFrame}>
          <div className={styles.chatLinkFrame}>
            <div className={styles.div}>{prop}</div>
            <div className={styles.monFriFrame}>
              <div className={styles.div1}>₩ 36,900</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpTextFrame;
