import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from "react-router";

function ItemPost() {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [stock, setStock] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('admin/item/post', {
                name: name,
                price: price,
                stock: stock
            });
            console.log(response.data);
            alert('등록 완료');
        } catch (error) {
            console.error('Error submitting item:', error);
            // 에러 처리 (예: 에러 메시지 표시)
        }
    };

    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate(`/item`); // 페이지 이동
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" value={name} onChange={e => setName(e.target.value)} />
            </label>
            <br />
            <label>
                Price:
                <textarea value={price} onChange={e => setPrice(e.target.value)} />
            </label>
            <br />
            <label>
                Stock:
                <textarea value={stock} onChange={e => setStock(e.target.value)} />
            </label>
            <br />
            <button type="submit">Submit</button>
            <input type="button" value="뒤로가기"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>

        </form>
    );
}

export default ItemPost;