import React, {useEffect, useLayoutEffect, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";
import DropdownMenu from "../../components/DropdownMenu";

function ItemMain() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('./post'); // 페이지 이동
    };
    // 리스트 객체
    const [itemList, setList] = useState([]);

    // 백엔드단에서 리스트 객체를 가져오는 부분
    useEffect(() => {
        axios.get("/item/list")
            .then(res => setList(res.data.data))
            .catch(error => console.log(error))

    }, []);
    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{backgroundColor: '#bfceda'}}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">On Spring</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="#">Store</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Menu
                                </a>
                                <ul class="dropdown-menu">
                                    <p>Brand</p>
                                    <li><a class="dropdown-item" href="#">Story</a></li>
                                    <li><a className="dropdown-item" href="#">Ingredients</a></li>
                                    <br/>
                                    <p>CS</p>
                                    <li><a class="dropdown-item" href="#">notice</a></li>
                                    <li><a class="dropdown-item" href="#">Q&A</a></li>
                                    <li><a className="dropdown-item" href="#">review</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                    </form>
                </div>
            </nav>
            <input type="button" value="추가하기"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>
            <div className="itemList">
                <table className="itemTable">
                    <thead>
                    <tr>
                        <th className="col-lg-2">
                            stock
                        </th>
                        <th className="col-lg-8">
                            name
                        </th>
                        <th className="col-lg-2">
                            price
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {itemList.filter(item => item.deleted !== 1).map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>{item.stock}</td>
                                <td className="list-title">
                                    <Link to={"/item/" + `${item.id}`+ "/detail"} className="title-link">
                                        {item.name}
                                    </Link>
                                </td>
                                <td>{item.price}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {/*<div className="itemList">*/}
            {/*    {itemList.map((item) => {*/}
            {/*        return (*/}
            {/*            <div className="card" style="width: 18rem;">*/}
            {/*                <img src="..." class="card-img-top" alt="..."> </img>*/}
            {/*                <div className="card-body">*/}
            {/*                    <h5 className="card-title">`${item.name}`</h5>*/}
            {/*                    /!*<p className="card-text">card's content.</p>*!/*/}
            {/*                    <Link to={"/item/" + `${item.id}`+ "/detail"} className="title-link">*/}
            {/*                        상세페이지*/}
            {/*                    </Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>
    );
}
export default ItemMain;