import {useCallback, useState} from "react";
import JoinAgreeContainer from "../../components/JoinAgreeContainer";
import { useNavigate } from "react-router-dom";
import FrameComponent6 from "../../components/FrameComponent6";
import styles from "./JoinAgree.module.css";

const JoinAgree = () => {
  const navigate = useNavigate();
  const [showTermsInfo, setShowTermsInfo] = useState(false);
  const [showPrivacyInfo, setShowPrivacyInfo] = useState(false);
  const [showThirdPartyInfo, setShowThirdPartyInfo] = useState(false);


  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const handleCancelClick = () => {
    navigate('/');
  };

  const handleNextClick = () => {
    navigate('/join');
  };


  return (
    <div className={styles.joinAgree}>
      <JoinAgreeContainer />
      <div className={styles.headerList} onClick={onHeaderListClick}>
        Brand
      </div>
      <div className={styles.headerList1} onClick={onHeaderList1Click}>
        CS
      </div>
      <div className={styles.headerList3} onClick={onHeaderList3Click}>
        Shop
      </div>
      <img
        className={styles.icon}
        alt=""
        src="/-1-1@2x.png"
        onClick={onImageClick}
      />
      <div className={styles.headerList4} onClick={onHeaderList4Click}>
        Cart
      </div>
      <div className={styles.divAgreeAllFrame}>
        <div className={styles.frameParent}>
          <div className={styles.joinParent}>
            <h1 className={styles.join}>Join</h1>
            <div className={styles.tEXTLabelAllAgree}>
              {/*<div className={styles.divagreeall}>*/}
              {/*  <div className={styles.input} />*/}
              {/*  <input*/}
              {/*    className={styles.label}*/}
              {/*    placeholder="모든 약관 동의"*/}
              {/*    type="text"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className={styles.divagreeinner}>
                <div className={styles.divAgreeAllContainer}>
                  <div className={styles.inputPersonalInfo}>
                    <input className={styles.input1} type="checkbox" />
                    <b className={styles.label1}>(필수)</b>
                    <b className={styles.label2}>이용약관 동의</b>
                    <img src="/direction-upsvg-1.svg" alt="info" className={styles.infoIcon} onClick={() => setShowTermsInfo(!showTermsInfo)} />
                    {showTermsInfo && <p className={styles.infoText}>제1조(목적)
                      이 약관은 주식회사 루나셀(이하 “회사”라 한다)가 운영하는 인터넷 웹사이트 및 온라인 쇼핑몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 “몰”과 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

                      제2조(정의)
                      ① “몰”이란 회사가 재화 또는 용역(이하 “재화 등”이라 한다)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 “몰”을 운영하는 사업자의 의미로도 사용합니다.
                      ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
                      ③ “회원”이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
                      ④ “비회원”이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.

                      제3조 통합운영
                      회사는 각종 브랜드사이트를 포함하여 회사가 운영하는 모든 사이트를 통합하여 운영합니다. 회사는 회원가입 시 통합운영에 대하여 공지하며, 회원은 회사가 운영하는 브랜드사이트와 기타 다른 사이트에서 동일 ID(아이디)와 비밀번호로 로그인하여 이용하실 수 있습니다.

                      제4조 (약관 등의 명시와 설명 및 개정)
                      ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 모사전송번호(fax 번호), 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보보호책임자 등을 이용자가 쉽게 알 수 있도록 “몰”의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                      ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 배송책임, 환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
                      ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                      ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
                      ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                      ⑥ 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴(이용계약의 해지)를 요청할 수 있으며, 회사가 제4항에 따라 변경된 약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우 약관 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 이용자가 명시적으로 약관 변경에 거부의사를 표시하지 아니하면, 회사는 이용자가 변경 약관에 동의한 것으로 간주합니다.
                      ⑦ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

                      제5조(서비스의 제공 및 변경)
                      ① “몰”은 다음과 같은 업무를 수행합니다.
                      1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
                      2. 구매계약이 체결된 재화 또는 용역의 배송
                      3. 기타 “몰”이 정하는 업무
                      ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                      ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
                      ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.

                      제6조(서비스의 중단)
                      ① “몰”은 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
                      ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                      ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제9조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.

                      제7조(회원가입)
                      ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
                      ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
                      1. 가입신청자가 이 약관 제8조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제8조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
                      2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
                      3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우
                      ③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.
                      ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.

                      제8조(회원 탈퇴 및 자격 상실 등)
                      ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.
                      ② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.
                      1. 가입 신청 시에 허위 내용을 등록한 경우
                      2. “몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
                      3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                      4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
                      ③ “몰”이 회원 자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
                      ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

                      제9조(회원에 대한 통지)
                      ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
                      ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 하며 이용자의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우에는 본 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.

                      제10조(구매신청 및 개인정보 제공 동의 등)
                      ① “몰” 이용자는 “몰” 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제 4호의 적용을 제외할 수 있습니다.
                      1. 재화 등의 검색 및 선택
                      2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
                      3. 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인
                      4. 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)
                      5. 재화 등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의
                      6. 결제방법의 선택
                      ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
                      ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

                      제11조 (계약의 성립)
                      ① “몰”은 제10조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
                      1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
                      2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
                      3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우
                      ② “몰”의 승낙이 제13조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                      ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.

                      제12조(지급방법)
                      “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                      1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
                      2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                      3. 온라인무통장입금
                      4. 현금 또는 자기앞수표에 의한 결제
                      5. 수령 시 대금지급
                      6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
                      7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제
                      8. 기타 전자적 지급 방법에 의한 대금 지급 등

                      제13조(수신확인통지․구매신청 변경 및 취소)
                      ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
                      ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제17조의 청약철회 등에 관한 규정에 따릅니다.

                      제14조(재화 등의 공급)
                      ① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.  이때 “몰”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
                      ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “몰”이 고의, 과실이 없음을 입증한 경우에는 그러하지 아니합니다.

                      제15조 포인트(마일리지) 적립 및 사용
                      ① 회원이 “몰” 에서 상품 및 서비스를 구매하는 경우 회사가 고지하는 적립율에 따라 포인트(마일리지)를 제공받으며 회원이 구매한 상품 또는 서비스를 반품, 환불할 경우 구매 시 적립된 해당 포인트가 삭감됩니다.
                      ② 적립된 포인트(마일리지)는 본인 확인 절차 후 본인만 사용가능하며 직계가족을 포함하여 타인에게 양도, 상속, 담보제공 등 제3자에게 처분하거나, 현금으로 환급 받을 수 없습니다.
                      ③ 1포인트는 1원으로 환산되어 적립된 포인트 한도 내에서 해당 포인트를 상품으로 무료 교환할 수 있으며, 현금 또는 신용카드와 합산하여 결제할 수 있습니다.
                      ④ 적립된 포인트를 사용하시면 해당 포인트만큼 차감됩니다.
                      ⑤ 포인트로 제공받은 제품은 다른 제품으로 교환 또는 환불되지 않습니다.
                      ⑥ 회원의 누적 포인트는 “몰” 에서 적립, 조회 및 사용이 가능합니다. 단, “몰”에서 특별 행사를 진행하는 기간에는 일시적으로 포인트 적립 및 사용이 제한될 수 있습니다. 이러한 경우에는 행사 이전에 “몰”에서 공지 합니다.
                      ⑦ 적립된 포인트의 유효기간은 1년으로, 1년이 경과된 포인트는 별도의 통보 없이 자동 소멸됩니다.
                      ⑧ 회원 탈퇴 및 자격 상실시 적립된 포인트(마일리지)는 소멸되며 일단 소멸된 포인트(마일리지)는 재생이 불가능합니다.
                      ⑨ 포인트(마일리지) 적립이 누락된 경우 구매 증빙자료(구매영수증 또는 카드대금 청구서)를 구입 후 1개월 이내에 “몰”에 제시하면 즉시 적립해 드립니다.
                      ⑩ 다음의 경우 포인트(마일리지)가 차감 혹은 임의 삭제될 수 있습니다.
                      1. 회사의 정책변경
                      2. 회원이 부정한 방법으로 포인트를 취득한 경우
                      ⑪ 다만, 전항 제1호의 사유로 포인트를 임의로 소멸하는 경우, 회사는 해당 사실을 최소 30일 전부터 회원에게 공지하여 회원이 이를 소비할 수 있도록 조치해야 합니다.
                      ⑫ 포인트 적립 방법 등 포인트 제도는 "회사" 또는 "몰"의 영업방침 및 사정에 따라 변경될 수 있습니다.
                      ⑬ "회사" 또는 "몰"의 정책에 따라 일부품목, 할인특매, 염가판매, 기획세트 등은 포인트 사용대상에서 제외될 수 있습니다.

                      제16조(환급)
                      “몰”은 이용자가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.

                      제17조(청약철회 등)
                      ① “몰”과 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
                      ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
                      1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                      2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
                      3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
                      4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
                      ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
                      ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.

                      제18조(청약철회 등의 효과)
                      ① “몰”은 이용자로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “몰”이 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
                      ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                      ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “몰”이 부담합니다.
                      ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.

                      제19조(개인정보보호)
                      ① “몰”은 이용자의 개인정보 수집 시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
                      ② “몰”은 회원가입 시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
                      ③ “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
                      ④ “몰”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
                      ⑤ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보보호책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
                      ⑥ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
                      ⑦ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
                      ⑧ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
                      ⑨ “몰”은 개인정보의 수집·이용·제공에 관한 동의란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절 시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
                      ⑩ "몰"은 정보통신서비스를 1년의 기간 동안 이용하지 아니하는 이용자(장기 미이용자)의 개인정보는 관련법령에 따라 해당기간 경과 후 즉시 파기하며 회원자격은 상실됩니다.

                      제20조(“몰“의 의무)
                      ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
                      ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
                      ③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시, 광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
                      ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.

                      제21조(회원의 ID 및 비밀번호에 대한 의무)
                      ① 제19조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
                      ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
                      ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.

                      제22조(이용자의 의무)
                      이용자는 다음 행위를 하여서는 안 됩니다.
                      1. 신청 또는 변경 시 허위 내용의 등록
                      2. 타인의 정보 도용
                      3. “몰”에 게시된 정보의 변경
                      4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                      5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해
                      6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                      7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위

                      제23조(연결 “몰”과 피연결 “몰” 간의 관계)
                      ① 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고 합니다.
                      ② 연결 “몰”은 피연결 “몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결 “몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.

                      제24조(저작권의 귀속 및 이용제한)
                      ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.
                      ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
                      ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.

                      제25조(서비스의 이용 환경)
                      ① 이용자는 앱스토어나 플레이스토어를 통해 단말기에 APP을 다운로드 및 설치하여 서비스를 이용할 수 있습니다. 이용자가 APP을 다운로드 및 설치하거나 서비스를 이용하는 경우, 이용자가 가입한 이동통신사에서 정한 별도의 요금이 발생하여 부과될 수 있는데, 이와 관련하여 회사는 책임을 지지 않습니다.
                      ② 이용자의 단말기, 이동통신사의 특성과 운영체제(OS), APP의 종류와 환경설정 등 이용자의 이용 환경에 따라 서비스의 전부 또는 일부의 이용이 제한될 수 있습니다. 회사는 이용자의 이용 환경으로 인하여 서비스의 전부 또는 일부의 이용이 제한되더라도 그로 인하여 발생하는 문제에 대하여 책임을 지지 않습니다.

                      제26조(분쟁해결)
                      ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치, 운영합니다.
                      ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
                      ③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

                      제27조(재판권 및 준거법)
                      ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 서울중앙지방법원을 관할법원으로 합니다.
                      ② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.

                      부칙
                        제1조 (시행일)
                        본 약관은 2024년 3월 14일부터 시행합니다.
                    </p>}
                  </div>
                  <div className={styles.labelPersonalInfoProvide}>
                    <div className={styles.listItemSMSConsent}>
                      <input className={styles.input1} type="checkbox" />
                    </div>
                    <b className={styles.label1}>(필수)</b>
                    <b className={styles.label4}>개인정보 수집 및 이용 동의</b>
                    <img src="/direction-upsvg-1.svg" alt="info" className={styles.infoIcon} onClick={() => setShowPrivacyInfo(!showPrivacyInfo)} />
                    {showPrivacyInfo && <p className={styles.infoText}>1. 개인정보의 수집목적 및 이용목적
                      회사((주)루나셀)는 이용자 확인, 이용대금 결제, 상품 배송 및 통계분석을 통한 마케팅 자료로서 이용자의 취향에 맞는 최적의 서비스를 제공하기 위한 목적으로 이용자의 개인정보를 수집, 이용하고 있습니다. 수집하는 개인정보 항목에 따른 구체적인 수집목적은 다음과 같습니다.
                      > 필수항목 - 성명, 본인확인 값, 아이디, 비밀번호, 이메일, 주소, 휴대폰번호, 생년월일, 수신동의 여부, 서비스 이용기록, 접속로그, 쿠키접속, IP정보, 결제기록, 모바일 단말기 고유번호, 단말기 OS 버전, 푸시 수신정보 >선택항목 - 암호화된 동일인 식별번호, 중복가입 확인정보, 이메일, 주소, 휴대폰번호, 수신동의여부
                      수집방법 : 홈페이지, 앱 · 인터넷 및 모바일 서비스 이용과정에서 자동으로 수집될 수 있는 정보 : 서비스이용기록, 접속로그, 쿠키 접속 IP정보, 결제기록, 모바일 단말기 고유번호, 단말기 OS, 단말기 OS버전, 푸시수신정보 이용자는 웹 브라우저에서 옵션을 설정함으로써 쿠키의 전부 허용, 일부 허용, 전부 거부 등의 선택이 가능합니다. 이용자는 개인정보의 수집/이용에 대한 동의를 거부할 수 있습니다. 다만, 이 경우 서비스 이용 및 혜택 제공에 제한을 받을 수 있습니다. 또한, 회원가입 시 필수정보의 수집, 이용 동의를 거부할 경우 서비스 제공에 제한이 있습니다.
                      2. 개인정보의 보유기간 및 이용기간
                      회사는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 이용자의 개인정보를 지체 없이 파기합니다. - 회원가입정보의 경우 : 회원가입을 탈퇴하거나 회원에서 제명된 때 - 대금지급정보의 경우 : 대금의 완제일 또는 채권소멸시효기간이 만료된 때 - 배송정보의 경우 : 물품 또는 서비스가 인도되거나 제공된 때 - 설문조사, 이벤트 등의 목적을 위하여 수집한 경우: 당해 설문조사, 이벤트 등이 종료한 때 다만, 상법, 소비자보호에 관한 법률 및 전자상거래 등 에서의 소비자보호에 관한 법률 등에 의하여 보존할 필요성이 있는 경우에는 다음과 같이 일정기간 이용자의 개인정보를 보유할 수 있습니다
                      표시/광고에 관한 기록 : 6개월(전자상거래등에서의 소비자보호에 관한 법률) 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률) 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래등에서의 소비자보호에 관한 법률) 거래에 관한 장부 또는 증빙서류 : 5년(국세기본법, 법인세법)
                      ■ 파기절차 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 됩니다. 동 개인정보는 법률에 의한 경우를 제외하고 보유되는 것 외에는 다른 목적으로 이용되지 않습니다.
                      ■ 파기방법 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                      ■ 개인정보 유효기간 제도 도입 ① 당사는 정보통신서비스를 1년의 기간 동안 이용하지 아니하는 이용자(장기 미이용자)의 개인정보는 관련법령에 따라 해당기간 경과 후 즉시 파기하며 회원자격은 상실됩니다. ② 미이용 기간은 최종 로그인 일자 및 구매, 경품수령, 상담이력으로 산정합니다. ③ 당사는 미이용자 개인정보 분리, 저장시점 도래 1개월 이전에 이메일 등을 통해 해당 이용자에게 관련 내용을 공지합니다.
                      다만, 정보통신서비스를 이용하는 자가 아닌 일반 이용자의 경우에는 2년이상 이용하지 아니하는 이용자의 개인정보에 대하여 법령에 의하여 보존할 필요성이 없는 한 복구할 수 없도록 파기하며 회원자격은 상실됩니다.</p>}

                  </div>
                  <div className={styles.inputPersonalInfo1}>
                    <input className={styles.input3} type="checkbox" />
                    <b className={styles.label5}>(필수)</b>
                    <b className={styles.label6}>개인정보 제3자 제공 동의</b>
                    <img src="/direction-upsvg-1.svg" alt="info" className={styles.infoIcon} onClick={() => setShowThirdPartyInfo(!showThirdPartyInfo)} />
                    {showThirdPartyInfo && <p className={styles.infoText}>회사는 보다 나은 서비스 제공, 고객편의 제공, 마케팅 등 원활한 업무 수행을 위하여 다음과 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 운영할 수 있습니다. 회사는 고객정보 보호서약 등을 통하여 개인정보보호 관련 법규의 준수, 개인정보에 관한 비밀유지, 제3자 제공에 대한 금지, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.

                      나이스평가정보(주) - 실명확인 및 본인인증 한진택배(주), 팀프레시 - 제품 및 경품의 배송, 배송지 확인 비엔비컴퍼니(주) - 개인정보의 전산처리 및 관리, SMS 및 이메일 등 발송시스템 연결 및 유지보수 씨제이올리브네트웍스(주) - SMS 발송을 위한 휴대폰번호 확인 이니시스,다날,네이버파이낸셜 - 결제대행업무
                    </p>}
                  </div>
                  {/*<div className={styles.linkCancelButtonNext}>*/}
                  {/*  <div className={styles.footerFrame}>*/}
                  {/*    <div className={styles.springIncOwnerInfo}>*/}
                  {/*      <input*/}
                  {/*        className={styles.heading3Input}*/}
                  {/*        type="checkbox"*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*    <b className={styles.heading3}>(선택)</b>*/}
                  {/*    <b className={styles.heading31}>*/}
                  {/*      쇼핑정보 수신 전체 동의*/}
                  {/*    </b>*/}
                  {/*  </div>*/}
                  {/*  <div className={styles.chatListItemLinkChat}>*/}
                  {/*    <div className={styles.monFri}>*/}
                  {/*      <input className={styles.upArrowIcon} type="checkbox" />*/}
                  {/*    </div>*/}
                  {/*    <b className={styles.listItem}>(선택)</b>*/}
                  {/*    <b className={styles.listItem1}> SMS 수신 동의</b>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.linkChat}>
            <button className={styles.link} onClick={handleCancelClick}>
              <span className={styles.div}>취소</span>
            </button>
            <button className={styles.button} onClick={handleNextClick}>
              <span className={styles.div1}>다음</span>
            </button>
          </div>
        </div>
      </div>
      <FrameComponent6 />
    </div>
  );
};

export default JoinAgree;
