import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GroupFrame.module.css";

const GroupFrame = () => {
  const navigate = useNavigate();

  const onListItem12Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onRectangleClick = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onRectangle2Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  return (
    <section className={styles.groupFrame}>
      <div className={styles.footerFrame}>
        <div className={styles.helpFrame}>
          <h1 className={styles.cs}>CS</h1>
        </div>
        <h3 className={styles.listItem} onClick={onListItem12Click}>
          Notice
        </h3>
        <div className={styles.qAText}>
          <div className={styles.chatListFrame}>
            <h3 className={styles.listItem1}>{`Q&A`}</h3>
            <div className={styles.monFriText}>
              <div className={styles.groupRectangle}>
                <h2 className={styles.listItemContainer}>
                  <span>{`QnA `}</span>
                  <span className={styles.span}>수정</span>
                </h2>
                <div className={styles.cancelRegisterGroup}>
                  <div className={styles.cancelRegisterGroupChild} />
                  <input
                    className={styles.listItem2}
                    placeholder="제목을 입력해주세요"
                    type="text"
                  />
                </div>
                <div className={styles.cancelRegisterGroup1}>
                  <div className={styles.cancelRegisterGroupItem} />
                  <div className={styles.listItem3}>내용을 입력해주세요</div>
                </div>
                <div className={styles.cancelRegisterGroup2}>
                  <div className={styles.cancelRegisterGroupInner} />
                  <input
                    className={styles.listItem4}
                    placeholder="비밀번호"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.chatItemLinkNoticeFrame}>
            <div className={styles.frameParent}>
              <button className={styles.rectangleParent}>
                <div className={styles.frameChild} onClick={onRectangleClick} />
                <div className={styles.div}>취소</div>
              </button>
              <button className={styles.rectangleGroup}>
                <div className={styles.frameItem} onClick={onRectangle2Click} />
                <div className={styles.div1}>등록</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GroupFrame;
