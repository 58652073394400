import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FrameComponent1.module.css";
import {useTranslation} from "react-i18next";
import {useAuth} from "./AuthContext";

const FrameComponent1 = () => {
  const navigate = useNavigate();


  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <div className={styles.component4Parent}>
      <p className={styles.hea5} onClick={changelanguageToEn}>
        English
      </p>
      <p className={styles.hea6} onClick={changelanguageToKo}>
        Korean
      </p>
      <div className={styles.component4}>
        <img
          className={styles.component4Child}
          alt=""
          src="/mainG.jpg"
        />
      </div>
      <div className={styles.parent}>
        <img className={styles.icon} loading="lazy" alt="" src="/logoWhite.png" />
        <header className={styles.headerListParent}>
          <div className={styles.headerList}>
            <h1 className={styles.headerList1} onClick={onHeaderList3Click}>
              Shop
            </h1>
            <h1 className={styles.headerList2} onClick={onHeaderListClick}>
              Brand
            </h1>
            {/*<div className={styles.headerList3}>B2B</div>*/}
            <h1 className={styles.headerList4} onClick={onHeaderList1Click}>
              CS
            </h1>

            <div className={styles.instagram1Wrapper}>
              <img
                className={styles.instagram1Icon}
                loading="lazy"
                alt=""
                src="/instagram.png"
              />
            </div>

          </div>
          <div className={styles.loginLinkFrameText}>
            <img
              className={styles.headerListItemLink}
              loading="lazy"
              alt=""
              src="/login_white.png"
              onClick={onHeaderListItemLinkClick}
            />
            <h1 className={styles.headerList5} onClick={onHeaderList4Click}>
              Cart
            </h1>

          </div>
        </header>
      </div>
      <div className={styles.sectionListItemHeadinWrapper}>
        <h1 className={styles.sectionList}>Review Event</h1>
      </div>
      <div className={styles.sectionListItemWrapper}>
        <div className={styles.sectionListContainer}>
          <span>
            <p className={styles.p}>{t('event1')}</p>
            <p className={styles.p1}>{t('event2')}</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
