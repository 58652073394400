import { useMemo } from "react";
import styles from "./FrameComponent4.module.css";

const FrameComponent4 = ({
  frameDivFlex,
  frameDivGap,
  frameDivWidth,
  instagram1IconFlex,
  onHeaderList3Click,
  onHeaderListClick,
  onHeaderList1Click,
  onHeaderListItemLinkClick,
  onHeaderList4Click,
}) => {
  const connectedNodesStyle = useMemo(() => {
    return {
      flex: frameDivFlex,
      gap: frameDivGap,
      width: frameDivWidth,
    };
  }, [frameDivFlex, frameDivGap, frameDivWidth]);

  const isolatedNodeStyle = useMemo(() => {
    return {
      flex: instagram1IconFlex,
    };
  }, [instagram1IconFlex]);

  return (
    <div className={styles.connectedNodes} style={connectedNodesStyle}>
      <div className={styles.isolatedNode} style={isolatedNodeStyle}>
        <h1 className={styles.headerList} onClick={onHeaderList3Click}>
          Shop
        </h1>
        <h1 className={styles.headerList1} onClick={onHeaderListClick}>
          Brand
        </h1>
        <h1 className={styles.headerList3} onClick={onHeaderList1Click}>
          CS
        </h1>
        <div className={styles.descendantNodes}>
          <img
            className={styles.instagram1Icon}
            loading="lazy"
            alt=""
            src="/instagram.png"
          />
        </div>
      </div>
      <div className={styles.uncleNodes}>
        <img
          className={styles.headerListItemLink}
          loading="lazy"
          alt=""
          src="/iconLogin.svg"
          onClick={onHeaderListItemLinkClick}
        />
        <h1 className={styles.headerList4} onClick={onHeaderList4Click}>
          Cart
        </h1>
      </div>
    </div>
  );
};

export default FrameComponent4;
