// NoticeUpdate.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function NoticeUpdate() {
    const { noticeId } = useParams();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    // 기존 공지사항 정보 로드
    useEffect(() => {
        axios.get(`/notice/${noticeId}`)
            .then(response => {
                setTitle(response.data.title);
                setContent(response.data.content);
            })
            .catch(error => console.error(error));
    }, [noticeId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`/notice/${noticeId}`, { title, content })
            .then(response => {
                console.log(response.data);
                // 성공적으로 업데이트 후 처리 (예: 리디렉션)
            })
            .catch(error => console.error(error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Title:
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
            </label>
            <br />
            <label>
                Content:
                <textarea value={content} onChange={e => setContent(e.target.value)} />
            </label>
            <br />
            <button type="submit">Update</button>
        </form>
    );
}

export default NoticeUpdate;
