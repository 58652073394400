import ProductInfoFrame from "./ProductInfoFrame";
import CartFrame from "./CartFrame";
import styles from "./ProductInfoCell.module.css";
import CartFrame1 from "./CartFrame1";

const ProductInfoCell = ({ key, itemName, price, count, imgUrl, onItemDelete }) => {
    return (
        <section className={styles.productInfoCell}>
            <div className={styles.thumbnailCell}>
                <div className={styles.frameParent}>
                    <div className={styles.headerRowCellParent}>
                        <div className={styles.headerRowCell}>
                            <input
                                className={styles.input}
                                placeholder="상품 정보"
                                type="text"
                            />
                        </div>
                        <button className={styles.headerRowCell1}>
                            <div className={styles.div}>수량</div>
                        </button>
                        <button className={styles.headerRowCell2}>
                            <div className={styles.div1}>주문금액</div>
                        </button>
                        <button className={styles.headerRowCell3}>
                            <div className={styles.div2}>옵션</div>
                        </button>
                    </div>
                    <CartFrame1 key={key} itemName={itemName} price={price} count={count} imgUrl={imgUrl}/>
                </div>
            </div>
        </section>
    );
};

export default ProductInfoCell;
