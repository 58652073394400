import React, {useCallback, useState} from 'react';
import axios from 'axios';
import '../../css/Login.css';
import axiosInstance from "../../components/axiosConfig";
import Cookies from 'js-cookie';
import {useNavigate} from "react-router";
import FrameComponent5 from "../../components/FrameComponent5";
import PasswordLabelFrame from "../../components/PasswordLabelFrame";
import Footer4 from "../../components/Footer4";
import styles from "./Login.module.css";
import {useAuth} from "../../components/AuthContext";

function Login() {
    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const navigate = useNavigate();

    const onListItemClick = useCallback(() => {
        navigate("/privacy-policy");
    }, [navigate]);

    const onListItem1Click = useCallback(() => {
        navigate("/termsofuse");
    }, [navigate]);

    const onListItem5Click = useCallback(() => {
        window.open("https://pf.kakao.com/_xfCsAG/chat");
    }, []);

    const [email, setEmail] = useState('test@a.com');
    const [password, setPassword] = useState('password');

    const [errors, setErrors] = useState({});

    const uri = 'https://nid.naver.com/oauth2.0/authorize?' +
        'response_type=code' +                  // 인증과정에 대한 내부 구분값 code 로 전공 (고정값)
        '&client_id=z9BuyGcrG1w1rBQ_8yVp' +     // 발급받은 client_id 를 입력
        '&state=NAVER_LOGIN_TEST' +             // CORS 를 방지하기 위한 특정 토큰값(임의값 사용)
        '&redirect_uri=https://localhost:8080/NaverLoginCallback';
        // '&redirect_uri=http://localhost:8080/';

    const validateForm = () => {
        let formIsValid = true;
        // let errors = {};

        if (!email) {
            formIsValid = false;
            errors['email'] = 'Email is required';
        }
        if (!password) {
            formIsValid = false;
            errors['password'] = 'Password is required';
        }
        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            alert("Please fill out all the fields correctly.");
            formIsValid = false;
        }
        return formIsValid;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('members/auth/login', {
                    email: email,
                    password: password
                });

                const token = response.data.data.accessToken;
                Cookies.set('token', token, {expires: 1, path: '/', httpOnly: true});
                console.log(token);
                setIsLoggedIn(true);
                navigate(`/`);
            } catch (error) {
                console.error(error); // 에러 처리
            }
        }
    };

    return(
        <div className={styles.login}>
            <FrameComponent5 />
            <div className={styles.kakaoLoginFormFrame}>
                <div className={styles.logInParent}>
                    <h1 className={styles.logIn}>Log In</h1>
                    <PasswordLabelFrame
                        email={email}
                        setEmail={setEmail}
                        password={password}
                        setPassword={setPassword}
                        errors={errors}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </div>
            <Footer4
                propMarginRight="unset"
                propPadding="var(--padding-138xl) var(--padding-5xl) 25.700000000000728px"
                propPadding1="0px 0px 0px var(--padding-1071xl)"
                propMargin="unset"
                onListItem4Click={onListItemClick}
                onListItem5Click={onListItem1Click}
                onListItem1Click={onListItem5Click}
            />
        </div>
        );
}

export default Login;