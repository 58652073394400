import styles from "./CartFrame1.module.css";
import {useCallback, useState} from "react";

const CartFrame = ({ key, itemName, price, count, imgUrl, onItemDelete }) => {
    const [variableValue, setVariableValue] = useState(count);
    const [cartItemId, setCartItemId] = useState(key);

    // '-' 버튼 클릭 핸들러
    const handleMinusClick = () => {
        setVariableValue(prevValue => Math.max(prevValue - 1, 1)); // 값이 0보다 작아지지 않도록
    };

    // '+' 버튼 클릭 핸들러
    const handlePlusClick = () => {
        setVariableValue(prevValue => prevValue + 1);
    };


    const handleDeleteClick = async () => {
        const response = await fetch(`/cart/${cartItemId}`, {
            method: 'DELETE'
        });
        if (response.ok) {
            // 성공적으로 삭제되었을 때의 로직
            console.log("아이템 삭제 성공");
        } else {
            // 오류 처리
            console.error("삭제 실패");
        }
    };


    return (
        <div className={styles.onSpringFooter}>
            <div className={styles.rectangleParent}>
                <div className={styles.frameChild} />
                <div className={styles.monFriListWrapper}>
                    <div className={styles.monFriList}>
                        <img
                            className={styles.thumbIcon}
                            loading="lazy"
                            alt=""
                            src={imgUrl}
                        />
                        <div className={styles.heading4TheSpraySetWrapper}>
                            <h1 className={styles.heading4}>{itemName}</h1>
                        </div>
                    </div>
                </div>
                <div className={styles.frameParent}>
                    <div className={styles.lineWrapper}>
                        <div className={styles.frameItem} />
                    </div>
                    <div className={styles.frameWrapper}>
                        <div className={styles.rectangleGroup} onClick={handleMinusClick}>
                            <div className={styles.frameInner} />
                            <div className={styles.div}>-</div>
                        </div>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={styles.div1}>{variableValue}</div>
                    </div>
                    <div className={styles.frameContainer}>
                        <div className={styles.rectangleContainer} onClick={handlePlusClick}>
                            <div className={styles.rectangleDiv} />
                            <div className={styles.div2}>+</div>
                        </div>
                    </div>
                    <div className={styles.lineContainer}>
                        <div className={styles.lineDiv} />
                    </div>
                    <div className={styles.heading4TheSpraySetContainer}>
                        <div className={styles.heading41}>{price}</div>
                    </div>
                    <div className={styles.lineFrame}>
                        <div className={styles.frameChild1} />
                    </div>
                    <div className={styles.container}>
                        <button onClick={handleDeleteClick} className={styles.deleteButton}>삭제</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CartFrame;
