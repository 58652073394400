import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../../components/Menu";
import Footerr from "../../components/Footerr";
import styles from "./CSMyPageProfile.module.css";

const CSMyPageProfile = () => {
  const navigate = useNavigate();

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem12Click = useCallback(() => {
    navigate("/csqnawrite");
  }, [navigate]);

  const onListItem2Click = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  return (
    <div className={styles.csMyPageProfile}>
      <div className={styles.listItem}>Profile</div>
      <div className={styles.headerList}>Cart</div>
      <div className={styles.category}>
        <div className={styles.listItem1}>Profile</div>
        <div
          className={styles.listItem2}
          onClick={onListItem12Click}
        >{`1:1 Q&A`}</div>
        <div className={styles.listItem3} onClick={onListItem2Click}>
          Order
        </div>
        <div className={styles.myPage}>My Page</div>
      </div>
      <div className={styles.div}>
        <div className={styles.div1}>
          <div className={styles.div2}>이메일</div>
          <div className={styles.child} />
        </div>
        <div className={styles.div3}>
          <div className={styles.div4}>비밀번호 변경</div>
          <div className={styles.div5}>비밀번호 확인</div>
          <div className={styles.item} />
          <div className={styles.inner} />
        </div>
        <div className={styles.div6}>
          <div className={styles.div7}>기본주소</div>
          <div className={styles.div8}>우편번호</div>
          <div className={styles.div9}>상세주소(선택)</div>
          <div className={styles.rectangleDiv} />
          <div className={styles.child1} />
          <div className={styles.child2} />
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild} />
            <div className={styles.heading4}>검색</div>
          </div>
        </div>
        <div className={styles.div10}>
          <div className={styles.child3} />
          <img
            className={styles.rectangleIcon}
            alt=""
            src="/rectangle-17.svg"
          />
          <div className={styles.child4} />
          <div className={styles.div11}>-</div>
          <div className={styles.div12}>-</div>
          <div className={styles.div13}>휴대폰 번호</div>
        </div>
        <div className={styles.lineDiv} />
        <div className={styles.rectangleGroup}>
          <div className={styles.groupItem} />
          <div className={styles.heading41}>수정하기</div>
        </div>
      </div>
      <Menu
        onHeaderListClick={onHeaderListClick}
        onHeaderList1Click={onHeaderList1Click}
        onHeaderList3Click={onHeaderList3Click}
        onHeaderListItemLinkClick={onHeaderListItemLinkClick}
        onHeaderList4Click={onHeaderList4Click}
        onImageClick={onImageClick}
      />
      <Footerr
        propTop="1400px"
        onListItem1Click={onListItem1Click}
        onListItem4Click={onListItem4Click}
        onListItem5Click={onListItem5Click}
      />
    </div>
  );
};

export default CSMyPageProfile;
