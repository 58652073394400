import { useMemo } from "react";
import styles from "./HeaderRowCell.module.css";

const HeaderRowCell = ({
  thumb,
  heading4TheSpraySet,
  heading4TheSpraySet1,
  propMinWidth,
}) => {
  const descendantThriceRemovedNodeStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  return (
    <div className={styles.headerRowCell}>
      <img className={styles.thumbIcon} loading="lazy" alt="" src={thumb} />
      <div
        className={styles.descendantThriceRemovedNode}
        style={descendantThriceRemovedNodeStyle}
      >
        <div className={styles.siblingThriceRemovedNodes}>
          <h2 className={styles.heading4}>{heading4TheSpraySet}</h2>
          <div className={styles.auntThriceRemovedNodes}>
            <div className={styles.heading41}>{heading4TheSpraySet1}</div>
            <div className={styles.div}>수량: 1개</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderRowCell;
