import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FrameComponent.module.css";

const FrameComponent = () => {
  const navigate = useNavigate();

  const onSectionListItemClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onSectionListItem1Click = useCallback(() => {
    navigate("/brandstory2");
  }, [navigate]);

  const onSectionListItem2Click = useCallback(() => {
    navigate("/brandstory4");
  }, [navigate]);

  return (
    <section className={styles.brandstoryWrapper}>
      <div className={styles.brandstory}>

        <div
            className={styles.sectionListItem1}
            onClick={onSectionListItem1Click}
        >
          <img
              className={styles.originIcon}
              loading="lazy"
              alt=""
              src="/onspa.jpg"
          />
          <h2 className={styles.factEffect}>On spa</h2>
          <div className={styles.div3}>:daily skin & body care</div>
        </div>
        <div
          className={styles.sectionListItem1}
          onClick={onSectionListItem1Click}
        >
          <img
            className={styles.originIcon}
            loading="lazy"
            alt=""
            src="/origin.jpg"
          />
          <h2 className={styles.factEffect}>Origin</h2>
          <div className={styles.div1}>:mineral water</div>
        </div>
        <div
          className={styles.sectionListItem2}
          onClick={onSectionListItem2Click}
        >
          <img
            className={styles.relaxationIcon}
            loading="lazy"
            alt=""
            src="/relaxation.jpg"
          />
          <h2 className={styles.sustainability}>Relaxation</h2>
          <div className={styles.div2}>:Anti-stress</div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
