import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Footer10.module.css";

const Footer10 = () => {
  const navigate = useNavigate();

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  return (
    <footer className={styles.footer}>
      <div className={styles.helpWrapper}>
        <div className={styles.help}>Help</div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.onSpringIncOwnerBryanKiParent}>
          <div className={styles.onSpringIncContainer}>
            <p className={styles.onSpringInc}>on spring Inc.</p>
            <p className={styles.ownerBryanKim}>
              Owner. Bryan Kim Business Reg N. 799-88-02348
            </p>
            <p className={styles.tel07044036450}>{`Tel. 070-4403-6450 `}</p>
            <p className={styles.emailInfoonSpringcokr}>
              Email. info@on-spring.co.kr
            </p>
            <p
              className={styles.address744Ho}
            >{`Address. 744 ho, 815, Daewangpangyo-ro, `}</p>
            <p className={styles.sujeongGuSeongnamSiGyeon}>
              Sujeong-gu, Seongnam-si, Gyeonggi-do
            </p>
          </div>
          <div className={styles.onSpring}>©on-spring</div>
        </div>
        <div className={styles.frameWrapper}>
          <div className={styles.chatItemParent}>
            <div className={styles.chatItem}>
              <div className={styles.listItem} onClick={onListItem4Click}>
                Privacy Policy
              </div>
              <div className={styles.listItem1} onClick={onListItem5Click}>
                Terms of Use
              </div>
            </div>
            <div className={styles.chat}>
              <div className={styles.listItem2}>Account Information</div>
              <div className={styles.listItemContainer}>
                <span>
                  <p className={styles.p}>우리은행 1005-180-571441</p>
                  <p className={styles.p1}>예금주: (주)루나셀</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.helpSection}>
          <div className={styles.footerFrame}>
            <div className={styles.listItem3} onClick={onListItem1Click}>
              1:1 Chat
            </div>
            <div className={styles.copyrightText}>
              <div className={styles.chatListItem}>
                <div className={styles.listItemContainer1}>
                  <span className={styles.listItemContainer2}>
                    <p className={styles.infoonSpringcokr}>
                      info@on-spring.co.kr
                    </p>
                    <p className={styles.p2}>070-4403-6450</p>
                  </span>
                </div>
                <div className={styles.listItemContainer3}>
                  <p className={styles.monFri10001700}>Mon-Fri 10:00-17:00</p>
                  <p className={styles.lunchTime12001330}>
                    Lunch Time 12:00-13:30
                  </p>
                </div>
              </div>
              <div className={styles.listItemContainer4}>
                <p
                  className={styles.qa}
                >{`유선상담이 어려운 경우 Q&A 게시판 혹은 `}</p>
                <p className={styles.p3}>{`카카오 플러스친구를 이용하면 `}</p>
                <p className={styles.p4}>원활한 상담이 가능합니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer10;
