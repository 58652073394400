import CollapsibleNode from "./CollapsibleNode";
import styles from "./Subtree.module.css";
import CollapsibleNode1 from "./CollapsibleNode1";
import {useTranslation} from "react-i18next";

const Subtree = ({addToCart, submitOrder}) => {
  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  return (
    <div className={styles.subtree}>
      <div className={styles.root}>
        <img
          className={styles.thumbIcon}
          loading="lazy"
          alt=""
          src="/thumbMistSet.jpg"
        />
        <div className={styles.merging}>
          <div className={styles.sibling}>
            <div className={styles.productDetailFrame}>
              <div className={styles.reviewFrame}>
                <div className={styles.discount}>
                  <div className={styles.discountChild} />
                  <div className={styles.tableBodyFrame}>35%</div>
                </div>
                <h1 className={styles.heading4}>[Set] Body Mist 2-Piece Set</h1>
                <div className={styles.optionLabelFrame}>
                  <div className={styles.requiredSymbolSVGFrame}>
                    <div className={styles.div}>₩ 54,000</div>
                    <div className={styles.productNameTextFrame}>
                      <div className={styles.div1}>₩ 34,900</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.mlX2}>
                {t('mist_set')}
              </div>
            </div>
            <CollapsibleNode1 propPadding="0px 0px 0px var(--padding-xs)" addToCart={addToCart} submitOrder={submitOrder}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subtree;
