import styles from "./ConnectionNode.module.css";

const ConnectionNode = () => {
  return (
    <div className={styles.connectionNode}>
      <div className={styles.divprddelivery}>
        <h1 className={styles.shippingReturn}>{`Shipping & Return`}</h1>
        <div className={styles.divcommonDetailContent}>
          <div className={styles.sequentialFlow}>
            <b className={styles.listItem}>택배사</b>
            <div className={styles.listItem1}>CJ대한통운 (1588-1255)</div>
          </div>
          <div className={styles.listItem2}>
            <b className={styles.b}>배송 비용</b>
            <div className={styles.div}>
              3,000원 (40,000원 이상 구매 시 무료배송), 도서-산간 지역은 별도의
              추가 금액이 발생합니다.
            </div>
          </div>
          <div className={styles.listItem3}>
            <b className={styles.b1}>배송 일정</b>
            <div className={styles.div1}>
              당일 12시 전 주문 시 당일 배송 가능, 지방-도서산간 등은 지역별
              리드타 임에 따라 2-3일 배송일정 소요 가능합니다.
            </div>
          </div>
          <div className={styles.sortingLogic}>
            <div className={styles.listItem4}>
              <b className={styles.b2}>배송 조회</b>
              <div className={styles.div2}>
                당일 12시 전 주문 시 당일 배송 가능, 상품의 재고 및 내부 상황에
                따라 다소 지연될 수 있습니다.
              </div>
            </div>
            <div className={styles.listItem5}>
              <b className={styles.b3}>교환 및 반품</b>
              <div className={styles.delayNode}>
                <div className={styles.timelineBuilder}>
                  <div className={styles.listItem6}>·</div>
                  <div className={styles.userInteractions}>
                    <div className={styles.listItem7}>·</div>
                    <div className={styles.listItem8}>·</div>
                    <div className={styles.listItem9}>·</div>
                  </div>
                  <div className={styles.dataVisualizer}>
                    <div className={styles.listItem10}>·</div>
                    <div className={styles.listItem11}>·</div>
                    <div className={styles.listItem12}>·</div>
                    <div className={styles.listItem13}>·</div>
                  </div>
                </div>
                <div className={styles.shapeCreator}>
                  <div className={styles.listItem14}>
                    단순변심, 착오구매에 따른 교환/반품 신청은 상품을 공급받은
                    날부터 7일 이내 가능합니다. (배송비 고객 부담)
                  </div>
                  <div className={styles.listItem15}>
                    다만, 공급받으신 상품 및 용역의 내용이 표시.광고 내용과
                    다르거나 다르게 이행된 경우에는 공급받은 날로부터 3개월
                    이내, 그 사실을 알게 된 날로부터 30일이내 교환/반품 신청을
                    하실 수 있습니다. (배송비 무료)
                  </div>
                  <div className={styles.listItem16}>
                    구매 시 사은품/ 증정품 등이 제공된 경우, 상품 교환/반품 시
                    함께 보내주셔야 합니다.
                  </div>
                  <div className={styles.listItemContainer}>
                    <p className={styles.p}>
                      반품 시, 상품대금 환불은 상품 회수 및 청약철회가 확정된
                      날로부터 3영업일 이내 진행되며, 기한을 초과한 경우 지연
                      기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률
                      시행령」 에서 정하는 이율을 곱하여
                    </p>
                    <p className={styles.p1}>산정한 지연이자를 지급합니다.</p>
                  </div>
                  <div className={styles.listItem17}>
                    시간의 경과에 의하여 재판매가 곤란한 정도로 상품 등의 가치가
                    현저히 감소한 경우 교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem18}>
                    고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히
                    감소한 경우 교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem19}>
                    고객님의 책임있는 사유로 상품 등이 멸실 또는 훼손된 경우
                    교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem20}>
                    오프라인 매장에서 구매한 제품은 교환/반품이 불가능합니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.listItem21}>
            <b className={styles.b4}>교환 및 반품 주소</b>
            <div className={styles.div3}>
              [13449] 경기도 성남시 수정구 대왕판교로 815, 7층 744호(시흥동,
              판교창조경제밸리)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectionNode;
