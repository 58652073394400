import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameShopHeader from "../../components/FrameShopHeader";
import FrameComponent2 from "../../components/FrameComponent2";
import PrdDetail from "../../components/PrdDetail";
import styles from "./ProductPageMist.module.css";
import ConnectionNode from "../../components/ConnectionNode";
import {useParams} from "react-router";
import axios from "axios";
import Footer from "../../components/Footer";
import {useAuth} from "../../components/AuthContext";

const ProductPageMist = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useAuth();


  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const itemId = 2;
  const [cartItems, setCartItems] = useState({
    id : '',
    name : '',
    price : '',
    salePrice : '',
    stock: '',
    content: '',
    imageUrl: "/thumbMist.jpg"
  });
  useEffect(() => {
    // 백엔드 서버로부터 공지사항 데이터를 가져옴
    axios.get(`/item/${itemId}`)
        .then(response => {
          if(response.data.code === 200) {
            setCartItems(prevItems => ({
              ...prevItems,  // 기존 상태의 모든 값들을 가져옴
              ...response.data.data,  // 서버 응답으로 받은 새 데이터를 병합
              imageUrl: response.data.data.imageUrl || prevItems.imageUrl  // 서버 응답에 imageUrl이 없다면 기존 imageUrl 유지
            }));

          } else {
            console.error("Error loading item");
          }
        })
        .catch(error => {
          console.error("Error fetching item:", error);
        });
  }, [itemId]); // itemId가 변경될 때마다 useEffect 실행


  const handleDelete = () => {
    axios.delete(`/item/${itemId}`)
        .then(response => {
          console.log(response.data);
          navigate('/item'); // 삭제 후 홈페이지로 이동
        })
        .catch(error => console.error(error));
  };

  const handleButtonClick = () => {
    navigate(`/item/${itemId}/update`); // 페이지 이동
  };

  const [quantity, setQuantity] = useState(1);

  // Submit order
  const submitOrder = async () => {
    try {
      console.log(cartItems)
      let total = quantity *
          navigate('/order', { state: { cartItems, quantity } });
    } catch (error) {
      console.error('Failed to place order:', error);
      alert('Failed to place order.');
    }
  };

  const addToCart = async () => {
    try {
      // 장바구니 추가 API 호출
      // 여기서는 예시로 '/cart' 엔드포인트를 사용합니다. 실제 엔드포인트는 백엔드 구현에 따라 달라집니다.
      const response = await axios.post('/cartcart', {
        itemId: cartItems.id, // 선택된 아이템의 ID
        count: quantity, // 선택된 수량
      });

      // 성공적으로 장바구니에 추가되었을 때의 처리
      alert('Successfully added to cart!');
    } catch (error) {
      // 오류 처리
      // console.error('Failed to add to cart:', error);
      alert('로그인이 필요합니다');
    }
  };


  return (
    <div className={styles.productPageMist}>
      <section className={styles.frameParent}>
        <FrameShopHeader
          onImageClick={onImageClick}
          onHeaderList3Click={onHeaderList3Click}
          onHeaderListClick={onHeaderListClick}
          onHeaderList1Click={onHeaderList1Click}
          onHeaderListItemLinkClick={onHeaderListItemLinkClick}
          onHeaderList4Click={onHeaderList4Click}
        />
        <div className={styles.frameGroup}>
          <FrameComponent2 addToCart={addToCart} submitOrder={submitOrder} />
          <PrdDetail />
          <div className={styles.frameWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.listItemLinkWrapper}>
                <div className={styles.listItem}>전성분</div>
              </div>
              <div className={styles.c121412Container}>
                <p
                  className={styles.c121412}
                >{`온천수, 글리세린, 1,2-헥산다이올, 프로판다이올, 판테놀, C12-14알케스-12, 정제수, 하이드로제네이티드레시틴, 알란토인, `}</p>
                <p
                  className={styles.p}
                >{`부틸렌글라이콜, 젤란검, 스페인감초뿌리추출물, 칼슘클로라이드, 다이포타슘글리시리제이트, 에틸헥실글리세린, 소듐시트레이트, `}</p>
                <p
                  className={styles.p1}
                >{`황련뿌리추출물, 다이소듐이디티에이, 시트릭애씨드, 레몬껍질오일, 광곽향오일, 베르가모트오일, 왕귤껍질오일, 세라마이드엔피, `}</p>
                <p className={styles.p2}>
                  달맞이꽃꽃추출물, 대왕소나무잎추출물, 칡뿌리추출물,
                  당느릅나무뿌리추출물, 맥아추출물, 라임전초오일,
                  유칼립투스잎오일, 향료, 리날룰, 리모넨
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ConnectionNode />
      <Footer
          onListItem4Click={onListItem4Click}
          onListItem5Click={onListItem5Click}
          onListItem1Click={onListItem1Click}
      />
    </div>
  );
};

export default ProductPageMist;
