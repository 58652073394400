import Header from "../../components/Header";
import ItemListFrame from "../../components/ItemListFrame";
import FooterTextFrame from "../../components/FooterTextFrame";
import Footer1 from "../../components/Footer1";
import styles from "./ProductPage.module.css";
import Footer from "../../components/Footer";

const ProductPage = () => {
  return (
    <div className={styles.productPage}>
      <section className={styles.frameContainer}>
        <Header />
        <ItemListFrame />
      </section>
      <FooterTextFrame />
      <Footer />
    </div>
  );
};

export default ProductPage;
