import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FooterTextFrame.module.css";
import {useTranslation} from "react-i18next";

const FooterTextFrame = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onListItemClick = useCallback(() => {
    navigate("/product-pageserum");
  }, [navigate]);

  const onDivitemDescriptionContainerClick = useCallback(() => {
    navigate("/product-pageserum");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    navigate("/product-pagemist");
  }, [navigate]);

  return (
    <section className={styles.footerTextFrame}>
      <div className={styles.bestSection}>
        <h3 className={styles.best}>Best</h3>
          <div className={styles.itemsContainer}>
            <div className={styles.listItem} onClick={onListItemClick}>
              <img
                className={styles.itemImgIcon}
                loading="lazy"
                alt=""
                src="/thumbSerum.jpg"
              />
              <div className={styles.productImageFrame}>
                <div
                  className={styles.divitemDescription}
                  onClick={onDivitemDescriptionContainerClick}
                >
                  <div className={styles.link}>
                    <div className={styles.div}>₩ 28,000</div>
                  </div>
                  <div className={styles.spraySetSection}>
                    <div className={styles.newYearEdition}>Body Serum</div>
                    <div className={styles.priceTag}>
                      <div className={styles.footerFrame}>
                        <div className={styles.div1}>₩ 27,000</div>
                        <div className={styles.chatItemFrame}>
                          <div className={styles.div2}>₩ 17,900</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.helpSectionLink}>
                    <div className={styles.sectionListContainer}>
                      <span className={styles.sectionListContainer1}>
                        <p className={styles.ml}>
                          {t('serum')}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.listItem1} onClick={onListItem1Click}>
              <img
                className={styles.itemImgIcon1}
                loading="lazy"
                alt=""
                src="/thumbMist.jpg"
              />
              <div className={styles.listItemInner}>
                <div className={styles.frameParent}>
                  <div className={styles.newYearEditionTheSprayParent}>
                    <div className={styles.newYearEdition1}>Body Mist</div>
                    <div className={styles.frameWrapper}>
                      <div className={styles.parent}>
                        <div className={styles.div3}>₩ 28,000</div>
                        <div className={styles.wrapper}>
                          <div className={styles.div4}>₩ 19,900</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mlWrapper}>
                    <div className={styles.ml1}>
                      {t('mist')}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.link1}>
                <div className={styles.div5}>₩ 27,000</div>
              </div>
            </div>
        </div>
      </div>
    </section>
  );
};

export default FooterTextFrame;
