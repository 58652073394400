import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import GroupFrame from "../../components/GroupFrame";
import Footer from "../../components/Footer";
import styles from "./CSQnaUpdate.module.css";

const CSQnaUpdate = () => {
  const navigate = useNavigate();

  const onListItem6Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem7Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem3Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <div className={styles.csQnaupdate}>
      <header className={styles.cSqnaUpdateContainer}>
        <div className={styles.headerFrame}>
          <img
            className={styles.icon}
            loading="lazy"
            alt=""
            src="/logoDarkgreen.png"
            onClick={onImageClick}
          />
          <div className={styles.shopFrame}>
            <div className={styles.listFrame}>
              <h1 className={styles.headerList} onClick={onHeaderList3Click}>
                Shop
              </h1>
              <h1 className={styles.headerList1} onClick={onHeaderListClick}>
                Brand
              </h1>
              <div className={styles.headerList2}>B2B</div>
              <h1 className={styles.headerList3} onClick={onHeaderList1Click}>
                CS
              </h1>
              <div className={styles.loginFrame}>
                <img
                  className={styles.instagram1Icon}
                  loading="lazy"
                  alt=""
                  src="/instagram.png"
                />
              </div>
            </div>
            <div className={styles.cSFrame}>
              <img
                className={styles.headerListItemLink}
                loading="lazy"
                alt=""
                src="/iconLogin.svg"
                onClick={onHeaderListItemLinkClick}
              />
              <h1 className={styles.headerList4} onClick={onHeaderList4Click}>
                Cart
              </h1>
            </div>
          </div>
        </div>
      </header>
      <GroupFrame />
      <Footer
        footerPadding="var(--padding-138xl) var(--padding-5xl) 25.300000000000185px"
        onListItem4Click={onListItem6Click}
        onListItem5Click={onListItem7Click}
        onListItem1Click={onListItem3Click}
      />
    </div>
  );
};

export default CSQnaUpdate;
