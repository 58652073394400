import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from "react-router";

function QnaDetail() {
    const {qnaId} = useParams();
    const [qna, setQna] = useState({
        id : '',
        title : '',
        content : '',
        date : '',
        user: ''
    });
    useEffect(() => {
        // 백엔드 서버로부터 공지사항 데이터를 가져옴
        axios.get(`/qna/${qnaId}`)
            .then(response => {
                if(response.data.code === 200) {
                    setQna(response.data.data);
                } else {
                    console.error("Error loading Qna");
                }
            })
            .catch(error => {
                console.error("Error fetching Qna:", error);
            });
    }, [qnaId]); // qnaId가 변경될 때마다 useEffect 실행

    const navigate = useNavigate();

    const handleDelete = () => {
        axios.delete(`/qna/${qnaId}`)
            .then(response => {
                console.log(response.data);
                navigate('/qna'); // 삭제 후 홈페이지로 이동
            })
            .catch(error => console.error(error));
    };

    const handleButtonClick = () => {
        navigate(`/qna/${qnaId}/update`); // 페이지 이동
    };

    return (
        <div className="qnaContent">
            <input type="button" value="삭제"  className="btn-input btn-toolbar btn-close-white" onClick={handleDelete}/>
            <input type="button" value="수정"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>

            <div>
                {qna.title} <br/>
            </div>
            <div>
                <hr/>
                <textarea readOnly={true} value={qna.content} >
                </textarea>
            </div>
            {/*<input type="button" value = "뒤로가기" onClick={handleHistory}  className="backToPage btn btn-toolbar btn-sm"/>*/}
        </div>

    );
}

export default QnaDetail;