import axios from 'axios';

// Axios 인스턴스 생성
const axiosInstance = axios.create({
    baseURL: 'http://localhost:8080', // 여기에 실제 API 기본 URL을 입력하세요.
});

// 요청을 보내기 전에 실행될 인터셉터를 추가합니다.
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken'); // 로컬 스토리지에서 토큰을 가져옵니다.
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`; // 헤더에 토큰을 추가합니다.
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
