import CollapsibleNode from "./CollapsibleNode";
import styles from "./SiblingNodes.module.css";
import {useTranslation} from "react-i18next";

const SiblingNodes = ({addToCart, submitOrder}) => {

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  return (
    <section className={styles.siblingNodes}>
      <div className={styles.connectedComponents}>
        <img
          className={styles.thumbIcon}
          loading="lazy"
          alt=""
          src="/thumbSerumMistSet.jpg"
        />
        <div className={styles.mergingGraph}>
          <div className={styles.decisiveJunction}>
            <div className={styles.helpFrame}>
              <div className={styles.ownerFrame}>
                <div className={styles.discount}>
                  <div className={styles.discountChild} />
                  <div className={styles.chatFrame}>33%</div>
                </div>
                <h1 className={styles.heading4}>
                  [Set] Body Serum + Body Mist Set
                </h1>
                <div className={styles.connectedComponentsTree}>
                  <div className={styles.complexNetwork}>
                    <div className={styles.div}>₩ 55,000</div>
                    <div className={styles.div1}>₩ 36,900</div>
                  </div>
                </div>
              </div>
              <div className={styles.ml}>
                {t('set')}
              </div>
            </div>
            <CollapsibleNode addToCart={addToCart} submitOrder={submitOrder} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiblingNodes;
