import {useCallback, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent3 from "../../components/FrameComponent3";
import Footer4 from "../../components/Footer4";
import styles from "./Join.module.css";
import {useTranslation} from "react-i18next";

const Join = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [address, setAddress] = useState('');
  const [zonecode, setZonecode] = useState(''); // 우편번호 상태

    const { t, i18n } = useTranslation()
    const changelanguageToKo = () => i18n.changeLanguage('ko')
    const changelanguageToEn = () => i18n.changeLanguage('en')

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const openPostcode = () => {
    setPopup(true); // 주소 검색 팝업을 띄우기 위해 상태 변경
  };



  return (
    <div className={styles.join}>
      <p className={styles.hea5} onClick={changelanguageToEn}>
        English
      </p>
      <p className={styles.hea6} onClick={changelanguageToKo}>
        Korean
      </p>
      <section className={styles.branchNode}>
        <div className={styles.leafNode}>
          <img
            className={styles.icon}
            loading="lazy"
            alt=""
            src="/logoDarkgreen.png"
            onClick={onImageClick}
          />
        </div>
        <FrameComponent3 />
      </section>
      <Footer4
        onListItem4Click={onListItem4Click}
        onListItem5Click={onListItem5Click}
        onListItem1Click={onListItem1Click}
      />
    </div>
  );
};

export default Join;
