import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Footer4 from "./Footer4";
import styles from "./FrameComponent6.module.css";

const FrameComponent6 = () => {
  const navigate = useNavigate();

  const onListItem2Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem3Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem6Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  return (
    <footer className={styles.instagram1Parent}>
      <img className={styles.instagram1Icon} alt="" src="/instagram-1.svg" />
      <img
        className={styles.headerListItemLink}
        alt=""
        src="/header--list--item--link--login-whitesvg.svg"
        onClick={onHeaderListItemLinkClick}
      />
      <Footer4
        propMarginRight="unset"
        propPadding="var(--padding-138xl) var(--padding-5xl) 25.700000000000728px"
        propMargin="0"
        onListItem4Click={onListItem2Click}
        onListItem5Click={onListItem3Click}
        onListItem1Click={onListItem6Click}
      />
    </footer>
  );
};

export default FrameComponent6;
