import InnerFrame from "../../components/InnerFrame";
import LoginSVG from "../../components/LoginSVG";
import styles from "./BrandStory4.module.css";

const BrandStory4 = () => {
  return (
    <div className={styles.brandstory4}>
      <div className={styles.divhinokAboutWrap} />
      <main className={styles.divswiperSlide}>
        <InnerFrame />
        <LoginSVG />
      </main>
    </div>
  );
};

export default BrandStory4;
