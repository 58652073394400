import styles from "./OnYangOnCheonSooGeemchoM.module.css";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const OnYangOnCheonSooGeemchoM = ({ addToCart, submitOrder }) => {
  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const [variableValue, setVariableValue] = useState(1);
  const [cost, setCost] = useState(17900);

  // '-' 버튼 클릭 핸들러
  const handleMinusClick = () => {
    setVariableValue(prevValue => Math.max(prevValue - 1, 1)); // 값이 0보다 작아지지 않도록
    setCost(prevValue => prevValue - 17900);
  };

  // '+' 버튼 클릭 핸들러
  const handlePlusClick = () => {
    setVariableValue(prevValue => prevValue + 1);
    setCost(prevValue => prevValue + 17900);
  };
  return (
    <div className={styles.frameWrapper}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.discountParent}>
            <div className={styles.discount}>
              <div className={styles.discountChild} />
              <div className={styles.div}>33%</div>
            </div>
            <h1 className={styles.heading4}>Body Mist</h1>
            <div className={styles.frameContainer}>
              <div className={styles.parent}>
                <div className={styles.div1}>₩ 27,000</div>
                <div className={styles.div2}>₩ 17,900</div>
              </div>
            </div>
          </div>
          <div className={styles.ml}>
            {t('mist')}
          </div>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.frameParent1}>
            <div className={styles.frameWrapper1}>
              <div className={styles.group}>
                <div className={styles.div3}>{t('quantity')}</div>
                <div className={styles.frameParent2}>
                  <div className={styles.rectangleParent} onClick={handleMinusClick}>
                    <div className={styles.frameChild} />
                    <div className={styles.div4} >-</div>
                  </div>
                  <div className={styles.div5}>{variableValue}</div>
                  <div className={styles.rectangleGroup} onClick={handlePlusClick}>
                    <div className={styles.frameItem} />
                    <div className={styles.div6} >+</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.totalParent}>
              <h3 className={styles.total}>Total</h3>
              <div className={styles.strong0}>₩{cost}</div>
            </div>
          </div>
        </div>
        <div className={styles.frameWrapper2}>
          <div className={styles.cartParent}>
            <button className={styles.cart} onClick={addToCart}>
              <div className={styles.cart1}>Cart</div>
            </button>
            <div className={styles.buyWrapper}>
              <button className={styles.buy} onClick={submitOrder}>
                <div className={styles.buy1}>Buy</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnYangOnCheonSooGeemchoM;
