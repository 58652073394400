import { useCallback } from "react";
import FrameComponent7 from "../../components/FrameComponent7";
import QALabel from "../../components/QALabel";
import { useNavigate } from "react-router-dom";
import Footer5 from "../../components/Footer5";
import styles from "./CSQnaDetail.module.css";
import Footer from "../../components/Footer";

const CSQnaDetail = () => {
  const navigate = useNavigate();

  const onRegistrationFrameClick = useCallback(() => {
    navigate("/csqa-page");
  }, [navigate]);

  return (
    <div className={styles.csQnadetail}>
      <FrameComponent7 />
      <section className={styles.qALabelWrapper}>
        <QALabel />
      </section>
      <div className={styles.footerHelp}>
        <div className={styles.div}>
          <div
            className={styles.registrationFrame}
            onClick={onRegistrationFrameClick}
          />
          <div className={styles.div1}>목록</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CSQnaDetail;
