import styles from "./PasswordLabelFrame.module.css";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useCallback} from "react";

const PasswordLabelFrame = ({ email, setEmail, password, setPassword, errors, handleSubmit }) => {
  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const navigate = useNavigate();

  const onJoinClick = useCallback(() => {
    navigate("/joinAgree");
  }, [navigate]);
  return (
    <form className={styles.passwordLabelFrame} onSubmit={handleSubmit}>
      <div className={styles.formLinkParent}>
        <button className={styles.formLink}>
          <img
            className={styles.loginKakaosvgFillIcon}
            alt=""
            src="/login-kakaosvg-fill@2x.png"
          />
          <div className={styles.formFieldsetFrame}>
            <div className={styles.div}>{t('kakao')}</div>
          </div>
        </button>
        <div className={styles.formFieldsetLink}>
          <img
            className={styles.loginNaversvgFillIcon}
            loading="lazy"
            alt=""
            src="/login-naversvg-fill@2x.png"
          />
          <div className={styles.div1}>{t('naver')}</div>
        </div>
        <button className={styles.formFieldsetLink1}>
          <div className={styles.google11Wrapper}>
            <img className={styles.google11} alt="" src="/google-1-1.svg" />
          </div>
          <div className={styles.div2}>{t('google')}</div>
        </button>
      </div>
      {/* 이메일 입력 필드 */}
        <input
            type="text"
            className={styles.formFieldsetLabel}
            placeholder="이메일"
            // value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <div className="error-message">{errors.email}</div>}

      <input
          type="password"
          className={styles.formFieldsetLabel}
          placeholder="비밀번호"
          // value={password}
          onChange={(e) => setPassword(e.target.value)}
      />
      {errors.password && <div className="error-message">{errors.password}</div>}



      <div className={styles.formFieldsetLinkFrame}>

        <button className={styles.formFieldsetLink2} type="submit">
          <span className={styles.div4}>{t('login')}</span>
        </button>

      </div>
      <div className={styles.iDFindLinkFrameParent}>
        {/*<div className={styles.iDFindLinkFrame}>*/}
        {/*  <div className={styles.passwordFindLinkFrame}>*/}
        {/*    <div className={styles.formFieldset1}>{t('find_id')}</div>*/}
        {/*    <div className={styles.formFieldsetLink3} />*/}
        {/*  </div>*/}
        {/*  <div className={styles.passwordFindLinkFrame1}>*/}
        {/*    <div className={styles.formFieldset2}>{t('find_pw')}</div>*/}
        {/*    <div className={styles.formFieldsetLink4} />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className={styles.formFieldsetLinkParent}>
          <div className={styles.formFieldset3} onClick={onJoinClick}>{t('join')}</div>
          <div className={styles.formFieldsetLink5} />
        </div>
      </div>
    </form>
  );
};

export default PasswordLabelFrame;
