import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./LoginSVG.module.css";

const LoginSVG = () => {
  const navigate = useNavigate();

  const onDivClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onDiv1Click = useCallback(() => {
    navigate("/brandstory2");
  }, [navigate]);

  const onDiv2Click = useCallback(() => {
    navigate("/brandstory4");
  }, [navigate]);

  return (
    <div className={styles.loginSVG}>
      <div className={styles.relaxationSection}>
        <h2 className={styles.relaxation}>Relaxation</h2>
        <div className={styles.activeTab}>
          <div className={styles.divParent}>
            <div className={styles.div} onClick={onDivClick} />
            <div className={styles.div1} onClick={onDiv1Click} />
            <div className={styles.divactive} />
            <div className={styles.div2} onClick={onDiv2Click} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSVG;
