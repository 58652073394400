import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./InnerFrame.module.css";
import { useTranslation } from "react-i18next";
import ShopHeader from "./ShopHeader";

const InnerFrame = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const changelanguageToKo = () => i18n.changeLanguage('ko');
  const changelanguageToEn = () => i18n.changeLanguage('en');

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
      <section className={styles.innerFrame}>
        <div className={styles.contentFrame}>
          <img
              className={styles.icon}
              loading="lazy"
              alt=""
              src="/logoWhite.png"
              onClick={onImageClick}
          />
        </div>
        <div className={styles.instagramFrame}>
          <div className={styles.frameWrapper}>
            <ShopHeader
                onHeaderList3Click={onHeaderList3Click}
                onHeaderListClick={onHeaderListClick}
                onHeaderList1Click={onHeaderList1Click}
                onHeaderListItemLinkClick={onHeaderListItemLinkClick}
                onHeaderList4Click={onHeaderList4Click}
            />
          </div>
          <div className={styles.relaxationFrame}>
            <div className={styles.antiStress}>Anti-stress</div>
            <div className={styles.naturalIngredientsText}>
              <div className={styles.div}>
                <p className={styles.p}>{t('brand3-1')}</p>
                <p className={styles.p1}>{t('brand3-2')}</p>
              </div>
              <div className={styles.div1}>
                <p className={styles.p2}>
                  {t('brand3-3')}
                </p>
                <p className={styles.p3}>
                  {t('brand3-4')}
                </p>
                <p className={styles.p4}>
                  {t('brand3-5')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default InnerFrame;
