import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ShopHeader from "../../components/ShopHeader";
import styles from "./BrandStory3.module.css";
import {useTranslation} from "react-i18next";

const BrandStory3 = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onDivClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onDiv1Click = useCallback(() => {
    navigate("/brandstory2");
  }, [navigate]);

  const onDiv2Click = useCallback(() => {
    navigate("/brandstory3");
  }, [navigate]);

  return (
    <div className={styles.brandstory4}>
      <div className={styles.divhinokAboutWrap} />
      <main className={styles.divswiperSlide}>
        <section className={styles.subHeaderParent}>
          <div className={styles.subHeader}>
            <div className={styles.primaryText}>
              <img
                className={styles.icon}
                loading="lazy"
                alt=""
                src="/logoWhite.png"
                onClick={onImageClick}
              />
              <div className={styles.secondaryFrame}>
                <h2 className={styles.family}>Family</h2>
              </div>
            </div>
          </div>
          <div className={styles.instagramIcon}>
            <header className={styles.loginSVG}>
              <ShopHeader
                onHeaderList3Click={onHeaderList3Click}
                onHeaderListClick={onHeaderListClick}
                onHeaderList1Click={onHeaderList1Click}
                onHeaderListItemLinkClick={onHeaderListItemLinkClick}
                onHeaderList4Click={onHeaderList4Click}
              />
            </header>
            <div className={styles.userFriendlyOnsen}>
              <div className={styles.activeDiv}>
                <div className={styles.tailoredForOur}>
                  Tailored for our family
                </div>
                <div className={styles.div}>
                  <p className={styles.p}>{t('brand4-1')}</p>
                  <p className={styles.p1}>{t('brand4-2')}</p>
                </div>
              </div>
              <div className={styles.div1}>
                <p className={styles.p2}>{t('brand4-3')}</p>
                <p className={styles.p3}>{t('brand4-4')}</p>
                <p className={styles.p4}>{t('brand4-5')}</p>
                <p className={styles.p5}>{t('brand4-6')}</p>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.containerFrame}>
          <div className={styles.activeDivGroup}>
            <div className={styles.div2} onClick={onDivClick} />
            <div className={styles.div3} onClick={onDiv1Click} />
            <div className={styles.div4} onClick={onDiv2Click} />
            <div className={styles.divactive} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default BrandStory3;
