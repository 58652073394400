import { useCallback } from "react";
import FrameComponent10 from "../../components/FrameComponent10";
import { useNavigate } from "react-router-dom";
import Footer9 from "../../components/Footer9";
import styles from "./CSNoticeDetail.module.css";
import Footer from "../../components/Footer";

const CSQAPage = () => {
    const navigate = useNavigate();

    const onListItem11Click = useCallback(() => {
        navigate("/csnotice-page");
    }, [navigate]);

    const onListItem12Click = useCallback(() => {
        navigate("/csqa-page");
    }, [navigate]);

    const onFrameClick = useCallback(() => {
        navigate("/csnotice-page");
    }, [navigate]);

    return (
        <div className={styles.csNoticedetail}>
            <FrameComponent10 />
            <section className={styles.qAList}>
                <div className={styles.line}>
                    <h1 className={styles.cs}>CS</h1>
                    <div className={styles.footer}>
                        <h3 className={styles.listItem} onClick={onListItem11Click}>
                            Notice
                        </h3>
                        <h3 className={styles.listItem} onClick={onListItem12Click}>
                            Q&A
                        </h3>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default CSQAPage;
