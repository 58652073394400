import styles from "./FrameShopHeader.module.css";

const FrameShopHeader = ({
  onImageClick,
  onHeaderList3Click,
  onHeaderListClick,
  onHeaderList1Click,
  onHeaderListItemLinkClick,
  onHeaderList4Click,
}) => {
  return (
    <header className={styles.frameShopHeader}>
      <div className={styles.frameMenuHeader}>
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoDarkgreen.png"
          onClick={onImageClick}
        />
        <div className={styles.loginLinkFrame}>
          <div className={styles.cartLinkFrame}>
            <h1 className={styles.headerList} onClick={onHeaderList3Click}>
              Shop
            </h1>
            <h1 className={styles.headerList1} onClick={onHeaderListClick}>
              Brand
            </h1>
            {/*<div className={styles.headerList2}>B2B</div>*/}
            <h1 className={styles.headerList3} onClick={onHeaderList1Click}>
              CS
            </h1>
            <div className={styles.tableFrame}>
              <img
                className={styles.instagram1Icon}
                loading="lazy"
                alt=""
                src="/instagram.png"
              />
            </div>
          </div>
          <div className={styles.requiredText}>
            <img
              className={styles.headerListItemLink}
              loading="lazy"
              alt=""
              src="/iconLogin.svg"
              onClick={onHeaderListItemLinkClick}
            />
            <h1 className={styles.headerList4} onClick={onHeaderList4Click}>
              Cart
            </h1>
          </div>
        </div>
      </div>
    </header>
  );
};

export default FrameShopHeader;
