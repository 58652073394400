import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";

function QnaMain() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('./post'); // 페이지 이동
    };
    // 리스트 객체
    const [qnaList, setList] = useState([]);

    // 백엔드단에서 리스트 객체를 가져오는 부분
    useEffect(() => {
        axios.get("/qna/list")
            .then(res => setList(res.data.data))
            .catch(error => console.log(error))

    }, []);
    return (
        <div>
            <input type="button" value="추가하기"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>
            <div className="qnaList">
                <table className="qnaTable">
                    <thead>
                    <tr>
                        <th className="col-lg-2">
                            번호
                        </th>
                        <th className="col-lg-8">
                            제목
                        </th>
                        <th className="col-lg-2">
                            작성일자
                        </th>
                        <th className="col-lg-2">
                            작성자
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {qnaList.map((qna) => {
                        return (
                            <tr key={qna.id}>
                                <td>{qna.id}</td>
                                <td className="list-title">
                                    <Link to={"/qna/" + `${qna.id}`+ "/detail"} className="title-link">
                                        {qna.title}
                                    </Link>
                                </td>
                                <td>{qna.date}</td>
                                <td>{qna.writer}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default QnaMain;