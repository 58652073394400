import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameShopHeader from "../../components/FrameShopHeader";
import SiblingNodes from "../../components/SiblingNodes";
import ConnectionNode from "../../components/ConnectionNode";
import Footer3 from "../../components/Footer3";
import styles from "./ProductPageSerumMistSet.module.css";
import {useParams} from "react-router";
import axios from "axios";
import Footer from "../../components/Footer";
import {useAuth} from "../../components/AuthContext";

const ProductPageSerumMistSet = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useAuth();


  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem6Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem2Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const [itemId] = useState(3);
  const [cartItems, setCartItems] = useState({
    id : '',
    name : '',
    price : '',
    salePrice : '',
    stock: '',
    content: '',
    imageUrl: "/thumbSerumMistSet.jpg"
  });

  useEffect(() => {
    axios.get(`/item/${itemId}`)
        .then(response => {
          if(response.data.code === 200) {
            console.log(response.data.data)
            setCartItems(response.data.data);
          } else {
            console.error("Error loading item");
          }
        })
        .catch(error => {
          console.error("Error fetching item:", error);
        });
  }, []);



  const handleDelete = () => {
    axios.delete(`/item/${itemId}`)
        .then(response => {
          console.log(response.data);
          navigate('/item'); // 삭제 후 홈페이지로 이동
        })
        .catch(error => console.error(error));
  };

  const handleButtonClick = () => {
    navigate(`/item/${itemId}/update`); // 페이지 이동
  };

  const [quantity, setQuantity] = useState(1);

  // Submit order
  const submitOrder = async () => {
    try {
      let total = quantity *
      navigate('/order', { state: { cartItems, quantity } });
    } catch (error) {
      console.error('Failed to place order:', error);
      alert('Failed to place order.');
    }
  };

  const addToCart = async () => {
    try {
      // 장바구니 추가 API 호출
      // 여기서는 예시로 '/cart' 엔드포인트를 사용합니다. 실제 엔드포인트는 백엔드 구현에 따라 달라집니다.
      const response = await axios.post('/cartcart', {
        itemId: cartItems.id, // 선택된 아이템의 ID
        count: quantity, // 선택된 수량
      });

      // 성공적으로 장바구니에 추가되었을 때의 처리
      alert('Successfully added to cart!');
    } catch (error) {
      // 오류 처리
      // console.error('Failed to add to cart:', error);
      alert('로그인이 필요합니다.');
    }
  };

  return (
    <div className={styles.productPageSerummistset}>
      <FrameShopHeader
        onImageClick={onImageClick}
        onHeaderList3Click={onHeaderList3Click}
        onHeaderListClick={onHeaderListClick}
        onHeaderList1Click={onHeaderList1Click}
        onHeaderListItemLinkClick={onHeaderListItemLinkClick}
        onHeaderList4Click={onHeaderList4Click}
      />
      <main className={styles.childNodeB}>
        <SiblingNodes addToCart={addToCart} submitOrder={submitOrder} />
        <section className={styles.prddetail}>
          <div className={styles.clusterNode}>
            <div className={styles.outlierNode}>
              <button className={styles.itemLink}>
                <div className={styles.detail}>Detail</div>
              </button>
              <button className={styles.itemLink1}>
                <div className={styles.review}>Review</div>
              </button>
            </div>
          </div>
          <img
            className={styles.icon}
            loading="lazy"
            alt=""
            src="/detailSerumMistSet.jpeg"
          />
        </section>
        <div className={styles.childNodeBInner}>
          <div className={styles.listItemLinkParent}>
            <div className={styles.listItem}>전성분</div>
            <div className={styles.c121412Container}>
              <p className={styles.p}>
                바디세럼:온천수, 프로판다이올, 다이프로필렌글라이콜, 글리세린,
                부틸렌글라이콜, 1,2-헥산다이올, 판테놀,
                암모늄아크릴로일다이메틸타우레이트
              </p>
              <p
                className={styles.c121412}
              >{`/브이피코폴리머, 하이드록시에틸셀룰로오스, 다이포타슘글리시리제이트, C12-14파레스-12, 카프릴릴글라이콜, 에틸헥실글리세린, `}</p>
              <p
                className={styles.p1}
              >{`하이드록시아세토페논, 다이소듐이디티에이, 인도멀구슬나무꽃추출물, 홀리바질잎추출물, 인도멀구슬나무잎추출물, 세라마이드엔피, `}</p>
              <p
                className={styles.p2}
              >{`울금뿌리추출물, 참산호말추출물, 아이비고드열매추출물, 하이드로제네이티드레시틴, 정제수, 글리세릴스테아레이트, 가지열매추출물, 콜레스테롤, `}</p>
              <p className={styles.p3}>
                알로에베라꽃추출물, 세라마이드엔에스, 세라마이드에이피,
                세라마이드에이에스, 세라마이드이오피, 향료, 리모넨, 리날룰,
                헥실신남알
              </p>
              <p className={styles.p4}>
                바디미스트:온천수, 프로판다이올, 다이프로필렌글라이콜, 글리세린,
                부틸렌글라이콜, 1,2-헥산다이올, 판테놀,
                암모늄아크릴로일다이메틸타우레이트
              </p>
              <p
                className={styles.c1214121}
              >{`/브이피코폴리머, 하이드록시에틸셀룰로오스, 다이포타슘글리시리제이트, C12-14파레스-12, 카프릴릴글라이콜, 에틸헥실글리세린, `}</p>
              <p
                className={styles.p5}
              >{`하이드록시아세토페논, 다이소듐이디티에이, 인도멀구슬나무꽃추출물, 홀리바질잎추출물, 인도멀구슬나무잎추출물, 세라마이드엔피, `}</p>
              <p
                className={styles.p6}
              >{`울금뿌리추출물, 참산호말추출물, 아이비고드열매추출물, 하이드로제네이티드레시틴, 정제수, 글리세릴스테아레이트, 가지열매추출물, 콜레스테롤, `}</p>
              <p className={styles.p7}>
                알로에베라꽃추출물, 세라마이드엔에스, 세라마이드에이피,
                세라마이드에이에스, 세라마이드이오피, 향료, 리모넨, 리날룰,
                헥실신남알
              </p>
            </div>
          </div>
        </div>
      </main>
      <ConnectionNode />
      <Footer
        onListItem4Click={onListItem5Click}
        onListItem5Click={onListItem6Click}
        onListItem1Click={onListItem2Click}
      />
    </div>
  );
};

export default ProductPageSerumMistSet;
