import React, { useState } from 'react';
import axios from 'axios';
import {useNavigate} from "react-router";

function NoticePost() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/notice/post', {
                title: title,
                content: content
            });
            console.log(response.data);
            alert('등록 완료');
            // 제출 후의 처리 (예: 알림 메시지, 페이지 이동 등)
        } catch (error) {
            console.error('Error submitting notice:', error);
            // 에러 처리 (예: 에러 메시지 표시)
        }
    };

    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate(`/notice`); // 페이지 이동
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Title:
                <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
            </label>
            <br />
            <label>
                Content:
                <textarea value={content} onChange={e => setContent(e.target.value)} />
            </label>
            <br />
            <button type="submit">Submit</button>
            <input type="button" value="뒤로가기"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>

        </form>
    );
}

export default NoticePost;