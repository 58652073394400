import styles from "./WelcomeContainer.module.css";
import {useEffect, useState} from "react";
import axios from "axios";

const WelcomeContainer = () => {
    const [name, setName] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/members/auth/status');
                setName(response.data.username);
                console.log(response.data)
                console.log(name); // 로그인 성공 처리
            } catch (error) {
                console.error(error); // 에러 처리
            }
        };
        fetchData();
    }, []);
  return (
    <div className={styles.parent}>
        {name && <div className={styles.div}>{name}님, 환영합니다.</div>}
      <div className={styles.groupChild} />
      <div className={styles.rectangleParent}>
        <div className={styles.groupItem} />
        <div className={styles.div1}>적립금</div>
        <div className={styles.div2}>2000 원</div>
      </div>
    </div>
  );
};

export default WelcomeContainer;
