import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import ProductPageSerum from "../pages/Item/ProductPageSerum";
import ProductPageMist from "../pages/Item/ProductPageMist";
import ProductPageSerumMistSet from "../pages/Item/ProductPageSerumMistSet";
import ProductPageMistSet from "../pages/Item/ProductPageMistSet";
import ProductPageSerumSet from "../pages/Item/ProductPageSerumSet";

const componentMap = {
    '1': ProductPageSerum,
    '2': ProductPageMist,
    '3': ProductPageSerumMistSet,
    '4': ProductPageMistSet,
    '5': ProductPageSerumSet
};

const ItemWrapper = () => {
    let { id } = useParams();
    let ComponentToRender = componentMap[id] || null;

    return (
        <div>
            {ComponentToRender ? <ComponentToRender /> : <div>Product not found.</div>}
        </div>
    );
};

export default ItemWrapper;
