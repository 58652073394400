import react, {useEffect} from "react";
import * as PortOne from "@portone/browser-sdk/v2";


export const onClickPayment = (paymentData) => {
    return new Promise((resolve, reject) => {
        const { IMP } = window;
        IMP.init('imp54150220'); // 본인의 아임포트 가맹점 식별코드로 변경

        const data = {
            pg: 'tosspayments',                           // PG사
            pay_method: 'TRANSFER',                           // 결제수단
            merchant_uid: `mid_${new Date().getTime()}`,   // 주문번호
            amount: paymentData.totalPrice,                                 // 결제금액
            customer_uid: 'cuid_1',
            customer_id: 'uid_1',
            name: '아임포트 결제 데이터 분석',                  // 주문명
            buyer_name: paymentData.name,                           // 구매자 이름
            buyer_tel: paymentData.phone,                     // 구매자 전화번호
            buyer_email: paymentData.email,               // 구매자 이메일
            buyer_addr: paymentData.address,                    // 구매자 주소
            buyer_postcode: paymentData.zonecode,                      // 구매자 우편번호
        };

        IMP.request_pay(data, (response) => {
            if (response.success) {
                resolve(response);
            } else {
                reject(response.error_msg);
            }
        });
    });
};

export const onClickPaymentTrans = (paymentData) => {
    return new Promise((resolve, reject) => {
        const { IMP } = window;
        IMP.init('imp54150220');

        const data = {
            pg: 'uplus',
            pay_method: 'trans',
            merchant_uid: `mid_${new Date().getTime()}`,
            amount: paymentData.totalPrice,
            name: paymentData.name || '아임포트 결제 데이터 분석',
            buyer_name: paymentData.name,
            buyer_tel: paymentData.phone,
            buyer_email: paymentData.email,
            buyer_addr: paymentData.address,
            buyer_postcode: paymentData.zonecode,
        };

        IMP.request_pay(data, (response) => {
            if (response.success) {
                resolve(response);
            } else {
                reject(response.error_msg);
            }
        });
    });
};
const Payment = () => {
    useEffect(() => {
        const loadScripts = () => {
            const jquery = document.createElement("script");
            jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
            document.head.appendChild(jquery);

            const iamport = document.createElement("script");
            iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
            document.head.appendChild(iamport);

            return () => {
                document.head.removeChild(jquery);
                document.head.removeChild(iamport);
            };
        };

        loadScripts();
    }, []);


    const onClickPaymentKakao = () => {
        const {IMP} = window;
        IMP.init('imp54150220');
        const data = {
            pg: 'kakaopay',                           // PG사
            // pay_method: 'vbank',                           // 결제수단
            merchant_uid: `mid_${new Date().getTime()}`,   // 주문번호
            amount: 1000,                                 // 결제금액
            name: '아임포트 결제 데이터 분석',                  // 주문명
            buyer_name: '홍길동',                           // 구매자 이름
            buyer_tel: '01012341234',                     // 구매자 전화번호
            buyer_email: 'example@example',               // 구매자 이메일
            buyer_addr: '신사동 661-16',                    // 구매자 주소
            buyer_postcode: '06018',                      // 구매자 우편번호
        };
        IMP.request_pay(data, (response) => {
            const {success, error_msg} = response;
            if (success) {
                alert('결제 성공');
            } else {
                alert(`결제 실패: ${error_msg}`);
            }
        });
    };

    const requestPayment = async (payMethod) => {
        try {
            const response = await PortOne.requestPayment({
                storeId: "store-5157b401-984f-4a47-8ef3-aba2d0d30a42",
                channelKey: "channel-key-a68bd08e-89f5-4602-944e-379772d2700c",
                paymentId: `payment-${crypto.randomUUID()}`,
                orderName: "나이키 와플 트레이너 2 SD",
                totalAmount: 1000,
                currency: "CURRENCY_KRW",
                payMethod: 'CARD',
                redirectUrl: `/payment-redirect`,
            });

            if (response.code != null) {
                // 오류 발생 시
                alert(response.message);
            } else {
                // 결제 성공 시 or 추가적인 처리 필요 시
                // 예: /payment/complete 엔드포인트로 결과 데이터 전송
                const notified = await fetch(`/payment/complete`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        paymentId: response.paymentId,
                        // 주문 정보...
                    }),
                });
            }
        } catch (error) {
            console.error("Payment request failed:", error);
            alert("결제 요청 중 오류가 발생했습니다.");
        }
    };

    return (
        <div>
            {/*<button onClick={requestPayment}>토스페이먼츠new</button>*/}

            <button onClick={onClickPayment}>토스페이먼츠-카드</button>
            <button onClick={onClickPaymentTrans}>토스페이먼츠-실시간계좌이체</button>
            <button onClick={onClickPaymentKakao}>카카오페이</button>
        </div>
    );
}

export default Payment;
