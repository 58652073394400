import { useCallback } from "react";
import FrameComponent10 from "../../components/FrameComponent10";
import { useNavigate } from "react-router-dom";
import Footer9 from "../../components/Footer9";
import styles from "./CSNoticeDetail.module.css";
import Footer from "../../components/Footer";

const CSNoticeDetail = () => {
  const navigate = useNavigate();

  const onListItem11Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onListItem12Click = useCallback(() => {
    navigate("/csqa-page");
  }, [navigate]);

  const onFrameClick = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  return (
    <div className={styles.csNoticedetail}>
      <FrameComponent10 />
      <section className={styles.qAList}>
        <div className={styles.line}>
          <h1 className={styles.cs}>CS</h1>
          <div className={styles.footer}>
            <h3 className={styles.listItem} onClick={onListItem11Click}>
              Notice
            </h3>
            <h3 className={styles.listItem} onClick={onListItem12Click}>
              Q&A
            </h3>
            {/*<div className={styles.ownerInfo}>*/}
            {/*  <div className={styles.chatList}>*/}
            {/*    /!*<h3*!/*/}
            {/*    /!*  className={styles.listItem1}*!/*/}
            {/*    /!*  onClick={onListItem12Click}*!/*/}
            {/*    /!*>{`Q&A`}</h3>*!/*/}
            {/*    <div className={styles.monFri}>*/}
            {/*      <div className={styles.noticeList}>*/}
            {/*        <div className={styles.menu}>*/}
            {/*          <div className={styles.listItem2}>Notice</div>*/}
            {/*          <img className={styles.menuChild} alt="" />*/}
            {/*        </div>*/}
            {/*        <div className={styles.chatWrapper}>*/}
            {/*          <div className={styles.chat}>*/}
            {/*            <h3 className={styles.listItem3}>적립금 소멸 안내</h3>*/}
            {/*            <div className={styles.listItem4}>2024.02.05</div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*        <img*/}
            {/*          className={styles.noticeListChild}*/}
            {/*          loading="lazy"*/}
            {/*          alt=""*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className={styles.image}>*/}
            {/*    <div className={styles.footerContainer}>*/}
            {/*      <img*/}
            {/*        className={styles.image1Icon}*/}
            {/*        loading="lazy"*/}
            {/*        alt=""*/}
            {/*        src="/image-1@2x.png"*/}
            {/*      />*/}
            {/*      <div className={styles.textWrapper}>*/}
            {/*        <div className={styles.text}>*/}
            {/*          <div className={styles.frame} onClick={onFrameClick} />*/}
            {/*          <h3 className={styles.h3}>목록</h3>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CSNoticeDetail;
