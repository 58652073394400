import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./JoinAgreeContainer.module.css";
import {useAuth} from "./AuthContext";

const JoinAgreeContainer = () => {
  const navigate = useNavigate();

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList8Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderList5Click = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList6Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList9Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <header className={styles.joinAgreeContainer}>
      <div className={styles.parent}>
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoDarkgreen.png"
          onClick={onImageClick}
        />
        <div className={styles.headerFrame}>
          <div className={styles.shopHeaderFrame}>
            <h1 className={styles.headerList} onClick={onHeaderList8Click}>
              Shop
            </h1>
            <h1 className={styles.headerList1} onClick={onHeaderList5Click}>
              Brand
            </h1>
            <h1 className={styles.headerList3} onClick={onHeaderList6Click}>
              CS
            </h1>
            <div className={styles.menuHeaderFrame}>
              <img
                className={styles.instagram1Icon}
                loading="lazy"
                alt=""
                src="/instagram.png"
              />
            </div>
          </div>
          <div className={styles.loginButtonFrame}>
            <img
              className={styles.headerListItemLink}
              loading="lazy"
              alt=""
              src="/iconLogin.svg"
              onClick={onHeaderListItemLinkClick}
            />
            <h1 className={styles.headerList4} onClick={onHeaderList9Click}>
              Cart
            </h1>
          </div>
        </div>
      </div>
    </header>
  );
};

export default JoinAgreeContainer;
