import React, {useState} from 'react';
import '../../css/Signup.css';
import DaumPost from './DaumPost';
import axios from "axios";

function Signup () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [popup, setPopup] = useState(false);
    const [address, setAddress] = useState('');
    const [zonecode, setZonecode] = useState(''); // 우편번호 상태


    const [errors, setErrors] = useState({});

    const [number, setNumber] = useState('');
    const [confirm, setConfirm] = useState('');
    const [mailNumberVisible, setMailNumberVisible] = useState(false);

    const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);

    let isConfirm = false;
    const sendNumber = async () => {
        await checkEmailDuplicate(email);
        if (!isEmailDuplicate) {
            try {
                axios.post('/mail', { email })
                    .then(response => {
                        alert("인증번호 발송");
                        setConfirm(response.data);
                        setMailNumberVisible(true);
                    })
                    .catch(error => console.error("Error:", error));
            } catch (error) {
                console.error(error);
            }
        }
        else {
            alert("이메일을 확인해주세요");
        }
    };

    const confirmNumber = () => {
        if (number == confirm) {
            alert("인증되었습니다.");
            isConfirm = true;
        } else {
            alert("번호가 다릅니다.");
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!email) {
            formIsValid = false;
            errors['email'] = 'Email is required';
        }
        if (!password) {
            formIsValid = false;
            errors['password'] = 'Password is required';
        }
        if (!username) {
            formIsValid = false;
            errors['username'] = 'Username is required';
        }
        if (!phone) {
            formIsValid = false;
            errors['phone'] = 'Phone number is required';
        }

        if (Object.keys(errors).length > 0) {
            alert("Please fill out all the fields correctly.");
            formIsValid = false;
        }

        setErrors(errors);
        return formIsValid;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm() && isConfirm) {
            try {
                const response = await axios.post('members/auth/signUp', {
                    email: email,
                    password: password,
                    username: username,
                    phone: phone
                });
                console.log(response.data);
            } catch (error) {
                console.error(error); // 에러 처리
            }
        }
    };

    const checkEmailDuplicate = async (email) => {
        const response = await axios.post(`/members/check-email`, {email})
            .then(response => {
                console.log(response.data);
                setIsEmailDuplicate(response.data);
            })
            .catch(error => console.error("Error:", error));
    };

    const handleComplete = (data) => {
        setPopup(!popup);
    }

    const openPostcode = () => {
        setPopup(true); // 주소 검색 팝업을 띄우기 위해 상태 변경
    };

    const handleAddress = (data) => {
        // DaumPost에서 주소 선택 시 호출될 함수
        console.log(data)
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        setAddress(fullAddress); // 선택한 주소를 상태에 저장
        setZonecode(data.zonecode); // 우편번호를 상태에 저장
        setPopup(false); // 주소 검색 팝업 닫기
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="container login-container">
                <div className="row">
                    <div className="col-md-6 login-form-1">
                        <h3>Sign Up</h3>
                        <div>
                            <div className="form-group">
                                <input type="text" id="login_username"  placeholder="Email *" value={email} onChange={e => setEmail(e.target.value)} onBlur={() => checkEmailDuplicate(email)} className={errors.email ? "error-input" : ""}/>
                                {isEmailDuplicate && <p>This email is already in use.</p>}
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <button type="button" onClick={sendNumber}>인증번호</button>
                            {mailNumberVisible && (
                                <div>
                                    <input
                                        type="String"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                        style={{width: '250px', marginTop: '-10px'}}
                                        placeholder="인증번호 입력"
                                    />
                                    <button type="button" onClick={confirmNumber}>이메일 인증</button>
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <input type="password" id="login_password" placeholder="Password *" value={password} onChange={e => setPassword(e.target.value)} className={errors.password ? "error-input" : ""}/>
                            {errors.password && <div className="error-message">{errors.password}</div>}
                        </div>
                        <div className="form-group">
                            <input type="username" id="signup_username" placeholder="Username *" value={username} onChange={e => setUsername(e.target.value)} className={errors.username ? "error-input" : ""}/>
                            {errors.username && <div className="error-message">{errors.username}</div>}
                        </div>
                        <div className="form-group">
                            <input type="phone" id="signup_phone" placeholder="Phone number *" value={phone} onChange={e => setPhone(e.target.value)} className={errors.phone ? "error-input" : ""}/>
                            {errors.phone && <div className="error-message">{errors.phone}</div>}
                        </div>
                        <br></br>
                        <div>
                            <div className="form-group">
                                <button type="button" onClick={() => setPopup(true)}>주소 검색</button>
                                {popup && <DaumPost onComplete={handleAddress} />}
                            </div>
                            <div className="form-group">
                                <label>우편번호:</label>
                                <input type="text" value={zonecode} readOnly />
                            </div>
                            <div className="form-group">
                                <label>선택된 주소:</label>
                                <input type="text" value={address} readOnly />
                            </div>
                        </div>



                        <div className="form-group">
                            <input type="submit" id="signup_btn" className="btnSubmit" value="Signup" />
                        </div>
                    </div>
                </div>
            </div>

        </form>
        );
    }

export default Signup;