import OnYangOnCheonSooGeemchoM from "./OnYangOnCheonSooGeemchoM";
import styles from "./FrameComponent2.module.css";

const FrameComponent2 = ({ addToCart, submitOrder }) => {
  return (
    <div className={styles.frameWrapper}>
      <div className={styles.thumbParent}>
        <img
          className={styles.thumbIcon}
          loading="lazy"
          alt=""
          src="/thumbMist.jpg"
        />
        <OnYangOnCheonSooGeemchoM addToCart={addToCart} submitOrder={submitOrder} />
      </div>
    </div>
  );
};

export default FrameComponent2;
