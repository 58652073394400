import React, {useEffect, useLayoutEffect, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom";

function NoticeMain() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('./post'); // 페이지 이동
    };
    // 리스트 객체
    const [noticeList, setList] = useState([]);

        // 백엔드단에서 리스트 객체를 가져오는 부분
    useEffect(() => {
        axios.get("/notice/list")
            .then(res => setList(res.data.data))
            .catch(error => console.log(error))

    }, []);
    return (
        <div>
            <input type="button" value="추가하기"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>
            <div className="noticeList">
                <table className="noticeTable">
                    <thead>
                    <tr>
                        <th className="col-lg-2">
                            번호
                        </th>
                        <th className="col-lg-8">
                            제목
                        </th>
                        <th className="col-lg-2">
                            작성일자
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {noticeList.map((notice) => {
                            return (
                                <tr key={notice.id}>
                                    <td>{notice.id}</td>
                                    <td className="list-title">
                                        <Link to={"/notice/" + `${notice.id}`+ "/detail"} className="title-link">
                                            {notice.title}
                                        </Link>
                                    </td>
                                    <td>{notice.date}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default NoticeMain;