import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FrameComponent7.module.css";

const FrameComponent7 = () => {
  const navigate = useNavigate();

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <section className={styles.csQnadetailInner}>
      <header className={styles.parent}>
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoDarkgreen.png"
          onClick={onImageClick}
        />
        <div className={styles.loginLink}>
          <div className={styles.cartLink}>
            <h3 className={styles.headerList} onClick={onHeaderList3Click}>
              Shop
            </h3>
            <h3 className={styles.headerList1} onClick={onHeaderListClick}>
              Brand
            </h3>
            <div className={styles.headerList2}>B2B</div>
            <h3 className={styles.headerList3} onClick={onHeaderList1Click}>
              CS
            </h3>
            <div className={styles.noticeText}>
              <img
                className={styles.instagram1Icon}
                loading="lazy"
                alt=""
                src="/instagram.png"
              />
            </div>
          </div>
          <div className={styles.deleteButton}>
            <img
              className={styles.headerListItemLink}
              loading="lazy"
              alt=""
              src="/iconLogin.svg"
              onClick={onHeaderListItemLinkClick}
            />
            <h3 className={styles.headerList4} onClick={onHeaderList4Click}>
              Cart
            </h3>
          </div>
        </div>
      </header>
    </section>
  );
};

export default FrameComponent7;
