import {useCallback, useEffect, useState} from "react";
import FrameComponent from "../../components/FrameComponent";
import ProductInfoCell from "../../components/ProductInfoCell";
import Footer10 from "../../components/Footer10";
import styles from "./Cart.module.css";
import FrameShopHeader from "../../components/FrameShopHeader";
import {useNavigate} from "react-router";
import {useAuth} from "../../components/AuthContext";

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    const onImageClick = useCallback(() => {
        navigate("/");
    }, [navigate]);

    const onHeaderList3Click = useCallback(() => {
        navigate("/product-page");
    }, [navigate]);

    const onHeaderListClick = useCallback(() => {
        navigate("/brandstory1");
    }, [navigate]);

    const onHeaderList1Click = useCallback(() => {
        navigate("/csnotice-page");
    }, [navigate]);

    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const onHeaderListItemLinkClick = useCallback(() => {
        if (isLoggedIn) {
            navigate('/csmy-page-order');  // 로그인 상태일 때
        } else {
            navigate('/login');  // 로그인 안된 상태일 때
        }
    }, [navigate]);

    const onHeaderList4Click = useCallback(() => {
        navigate("/cart");
    }, [navigate]);

    const loadCartData = () => {
        fetch('/cartView')
            .then(response => response.json())
            .then(data => {
                setCartItems(data.cartItems);
                const totalAmount = data.cartItems.reduce((sum, item) => sum + item.salePrice * item.count, 0);
                setTotal(totalAmount);
                console.log(data.cartItems);
            })
            .catch(error => console.error('Error fetching cart data:', error));
    };

    useEffect(() => {
        loadCartData();
    }, []);
    // useEffect(() => {
    //     // "/cart" 경로에서 카트 정보를 가져옵니다.
    //     fetch('/cartView')
    //         .then(response => response.json()) // 응답을 JSON으로 변환
    //         .then(data => {
    //             setCartItems(data.cartItems); // 카트 아이템 목록 설정
    //             const totalAmount = data.cartItems.reduce((sum, item) => sum + item.salePrice * item.count, 0); // 총액 계산
    //             setTotal(totalAmount); // 총액 설정
    //             console.log(data); // 데이터 로깅
    //         })
    //         .catch(error => console.error('Error fetching cart data:', error));
    // }, []);

    const onBuyClick = useCallback(() => {
        // 'Buy' 버튼 클릭 시 /order 페이지로 네비게이션하고, 카트 아이템 데이터를 전달합니다.
        navigate('/order', { state: { cartItems, total } });
    }, [navigate, cartItems, total]); // 의존성 배열에 navigate, cartItems, total 추가

    const handleDeleteItem = async (cartItemId) => {
        const response = await fetch(`/cart/${cartItemId}`, {
            method: 'DELETE'
        });
        if (response.ok) {
            // 서버에서 아이템 삭제 후 클라이언트 상태 업데이트
            loadCartData();
        } else {
            // 오류 처리
            console.error("삭제 실패");
        }
    };

    return (
        <div className={styles.cart}>
            <FrameShopHeader
                    onImageClick={onImageClick}
                    onHeaderList3Click={onHeaderList3Click}
                    onHeaderListClick={onHeaderListClick}
                    onHeaderList1Click={onHeaderList1Click}
                    onHeaderListItemLinkClick={onHeaderListItemLinkClick}
                    onHeaderList4Click={onHeaderList4Click}
            />
            {cartItems.map((item) => (
                <ProductInfoCell
                    key={item.cartItemId}
                    itemName={item.itemName}
                    price={item.salePrice}
                    count={item.count}
                    imgUrl={item.imageUrl}
                    onItemDelete={() => handleDeleteItem(item.cartItemId)}
                />
            ))}
            <div className={styles.cartInner}>
                <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                        <div className={styles.totalWrapper}>
                            <h3 className={styles.total}>Total: ${total}</h3>
                        </div>
                    </div>
                    <button className={styles.buy} onClick={onBuyClick}>
                        <div className={styles.buy1}>Buy</div>
                    </button>
                </div>
            </div>
            <Footer10 />
        </div>
    );
};

export default Cart;