import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent4 from "./FrameComponent4";
import styles from "./FrameComponent5.module.css";
import {useAuth} from "./AuthContext";

const FrameComponent5 = () => {
  const navigate = useNavigate();

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);



  return (
    <header className={styles.logoFrameWrapper}>
      <div className={styles.logoFrame}>
        <img
          className={styles.icon}
          loading="lazy"
          alt=""
          src="/logoDarkgreen.png"
          onClick={onImageClick}
        />
        <FrameComponent4
          frameDivFlex="unset"
          frameDivGap="0px 191px"
          frameDivWidth="914.5px"
          instagram1IconFlex="1"
          onHeaderList3Click={onHeaderList3Click}
          onHeaderListClick={onHeaderListClick}
          onHeaderList1Click={onHeaderList1Click}
          onHeaderListItemLinkClick={onHeaderListItemLinkClick}
          onHeaderList4Click={onHeaderList4Click}
        />
      </div>
    </header>
  );
};

export default FrameComponent5;
