import styles from "./ShopHeader.module.css";

const ShopHeader = ({
  onHeaderList3Click,
  onHeaderListClick,
  onHeaderList1Click,
  onHeaderListItemLinkClick,
  onHeaderList4Click,
}) => {
  return (
    <div className={styles.shopHeader}>
      <div className={styles.menuItem}>
        <div className={styles.headerList} onClick={onHeaderList3Click}>
          Shop
        </div>
        <div className={styles.headerList1} onClick={onHeaderListClick}>
          Brand
        </div>
        <div className={styles.headerList3} onClick={onHeaderList1Click}>
          CS
        </div>
        <div className={styles.instagramFrame}>
          <img
            className={styles.instagram1Icon}
            loading="lazy"
            alt=""
            src="/instagram.png"
          />
        </div>
      </div>
      <div className={styles.cartText}>
        <img
          className={styles.headerListItemLink}
          loading="lazy"
          alt=""
          src="/iconLogin.svg"
          onClick={onHeaderListItemLinkClick}
        />
        <div className={styles.headerList4} onClick={onHeaderList4Click}>
          Cart
        </div>
      </div>
    </div>
  );
};

export default ShopHeader;
