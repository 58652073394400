import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import HelpTextFrame from "./HelpTextFrame";
import styles from "./ItemListFrame.module.css";
import {useTranslation} from "react-i18next";

const ItemListFrame = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onDivitemDescriptionContainerClick = useCallback(() => {
    navigate("/product-pageserummistset");
  }, [navigate]);

  const onDivitemDescriptionContainer2Click = useCallback(() => {
    navigate("/product-pageserumset");
  }, [navigate]);

  const onListItemClick = useCallback(() => {
    navigate("/product-pageserummistset");
  }, [navigate]);

  const onListItem2Click = useCallback(() => {
    navigate("/product-pagemistset");
  }, [navigate]);

  const onDivitemDescriptionContainer3Click = useCallback(() => {
    navigate("/product-pagemistset");
  }, [navigate]);

  const onListItem3Click = useCallback(() => {
    navigate("/product-pageserumset");
  }, [navigate]);

  return (
    <div className={styles.itemListFrame}>
      <div className={styles.itemImageFrame}>
        <div className={styles.descriptionFrame}>
          <h3 className={styles.set}>Set</h3>
        </div>
        <div className={styles.priceFrame}>
          <div className={styles.listItem} onClick={onListItemClick}>
            <img
              className={styles.itemImgIcon}
              loading="lazy"
              alt=""
              src="/thumbSerumMistSet.jpg"
            />
            <HelpTextFrame
              // ml195ml={t('set')}
              setBodySerumBodyMistSet="[Set] Body Serum + Body Mist Set"
              prop="₩ 55,000"
              onDivitemDescriptionContainerClick={
                onDivitemDescriptionContainerClick
              }
            />
          </div>
          <div className={styles.productListFrame}>
            <div className={styles.listItem1} onClick={onListItem2Click}>
              <img
                className={styles.itemImgIcon1}
                loading="lazy"
                alt=""
                src="/thumbMistSet.jpg"
              />
            </div>
            <div
              className={styles.divitemDescription}
              onClick={onDivitemDescriptionContainer3Click}
            >
              <div className={styles.sectionListContainer}>
                <span className={styles.sectionListContainer1}>
                  {/*<p className={styles.ml2}>{t('mist_set')}</p>*/}
                </span>
              </div>
              <div className={styles.setBodyMist}>
                [Set] Body Mist 2-Piece Set
              </div>
              <div className={styles.productItemLinkFrame}>
                <div className={styles.productItemChatFrame}>
                  <div className={styles.div}>₩ 54,000</div>
                  <div className={styles.productItemBusinessInfoFra}>
                    <div className={styles.div1}>₩ 33,900</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.productDivisionFrame}>
            <div className={styles.listItemParent}>
              <div className={styles.listItem2} onClick={onListItem3Click}>
                <img
                  className={styles.itemImgIcon2}
                  loading="lazy"
                  alt=""
                  src="/thumbSerumSet.jpg"
                />
              </div>
              <HelpTextFrame
                // ml195ml={t('serum_set')}
                setBodySerumBodyMistSet="[Set] Body Serum 2-Piece Set"
                prop="₩ 56,000"
                propPadding="0px var(--padding-6xl)"
                onDivitemDescriptionContainerClick={
                  onDivitemDescriptionContainer2Click
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemListFrame;
