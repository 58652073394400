import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameShopHeader from "../../components/FrameShopHeader";
import ConnectionNode from "../../components/ConnectionNode";
import Footer2 from "../../components/Footer2";
import styles from "./ProductPageSerumSet.module.css";
import {useParams} from "react-router";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Footer from "../../components/Footer";
import {useAuth} from "../../components/AuthContext";

const ProductPageSerumSet = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(37900);
  const { isLoggedIn, setIsLoggedIn } = useAuth();


  const { t, i18n } = useTranslation()
  const changelanguageToKo = () => i18n.changeLanguage('ko')
  const changelanguageToEn = () => i18n.changeLanguage('en')


  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem6Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem2Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  // '-' 버튼 클릭 핸들러
  const handleMinusClick = () => {
    setQuantity(prevValue => Math.max(prevValue - 1, 1)); // 값이 0보다 작아지지 않도록
    setCost(prevValue => prevValue - 37900);
  };

  // '+' 버튼 클릭 핸들러
  const handlePlusClick = () => {
    setQuantity(prevValue => prevValue + 1);
    setCost(prevValue => prevValue + 37900);
  };

  const [itemId] = useState(5);
  const [cartItems, setCartItems] = useState({
    id : '',
    name : '',
    price : '',
    salePrice : '',
    stock: '',
    content: '',
    imageUrl: "/thumbSerumSet.jpg"
  });
  useEffect(() => {
    // 백엔드 서버로부터 공지사항 데이터를 가져옴
    axios.get(`/item/${itemId}`)
        .then(response => {
          if(response.data.code === 200) {
            setCartItems(response.data.data);
          } else {
            console.error("Error loading item");
          }
        })
        .catch(error => {
          console.error("Error fetching item:", error);
        });
  }, [itemId]); // itemId가 변경될 때마다 useEffect 실행


  const handleDelete = () => {
    axios.delete(`/item/${itemId}`)
        .then(response => {
          console.log(response.data);
          navigate('/item'); // 삭제 후 홈페이지로 이동
        })
        .catch(error => console.error(error));
  };

  const handleButtonClick = () => {
    navigate(`/item/${itemId}/update`); // 페이지 이동
  };



  // Submit order
  const submitOrder = async () => {
    try {
      let total = quantity *
          navigate('/order', { state: { cartItems, quantity } });
    } catch (error) {
      // console.error('Failed to place order:', error);
      alert('로그인이 필요합니다.');
    }
  };

  const addToCart = async () => {
    try {
      // 장바구니 추가 API 호출
      // 여기서는 예시로 '/cart' 엔드포인트를 사용합니다. 실제 엔드포인트는 백엔드 구현에 따라 달라집니다.
      const response = await axios.post('/cartcart', {
        itemId: cartItems.id, // 선택된 아이템의 ID
        count: quantity, // 선택된 수량
      });

      // 성공적으로 장바구니에 추가되었을 때의 처리
      alert('Successfully added to cart!');
    } catch (error) {
      // 오류 처리
      console.error('Failed to add to cart:', error);
      alert('로그인이 필요합니다');
    }
  };

  return (
    <div className={styles.productPageSerumset}>
      <FrameShopHeader
        onImageClick={onImageClick}
        onHeaderList3Click={onHeaderList3Click}
        onHeaderListClick={onHeaderListClick}
        onHeaderList1Click={onHeaderList1Click}
        onHeaderListItemLinkClick={onHeaderListItemLinkClick}
        onHeaderList4Click={onHeaderList4Click}
      />
      <main className={styles.siblingNodes}>
        <section className={styles.parentNode}>
          <div className={styles.ancestorNode}>
            <img
              className={styles.thumbIcon}
              loading="lazy"
              alt=""
              src="/thumbSerumSet.jpg"
            />
            <div className={styles.siblingsGroup}>
              <div className={styles.childNodes}>
                <div className={styles.subtree}>
                  <div className={styles.itemLinkFrame}>
                    <div className={styles.discount}>
                      <div className={styles.discountChild} />
                      <div className={styles.onYangOncheon}>32%</div>
                    </div>
                    <h1 className={styles.heading4}>
                      [Set] Body Serum 2-Piece Set
                    </h1>
                    <div className={styles.helpTextFrame}>
                      <div className={styles.ownerInfoFrame}>
                        <div className={styles.div}>₩ 56,000</div>
                        <div className={styles.chatFrame}>
                          <div className={styles.div1}>₩ 37,900</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.mlX2}>
                    {t('serum_set')}
                  </div>
                </div>
                <div className={styles.subbranchNodes}>
                  <div className={styles.trunkSubbranches}>
                    <div className={styles.crownSubbranches}>
                      <div className={styles.nestedBranching}>
                        <h3 className={styles.h3}>{t('quantity')}</h3>
                        <div className={styles.leafyHierarchy}>
                          <div className={styles.trunkHierarchy} onClick={handleMinusClick}>
                            <div className={styles.crownHierarchy} />
                            <h3 className={styles.subtreeNetwork} >-</h3>
                          </div>
                          <div className={styles.branchingNetwork}>{quantity}</div>
                          <div className={styles.trunkHierarchy1} onClick={handlePlusClick}>
                            <div className={styles.trunkHierarchyChild} />
                            <h3 className={styles.h31} >+</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.detailReviewFrame}>
                      <h1 className={styles.total}>Total</h1>
                      <div className={styles.strong0}>₩{cost}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.branchingSystem}>
                  <div className={styles.chatItemFrame}>
                    <button className={styles.cart} onClick={addToCart}>
                      <div className={styles.cart1}>Cart</div>
                    </button>
                    <div className={styles.monFriTextFrame}>
                      <button className={styles.buy} onClick={submitOrder}>
                        <div className={styles.buy1}>Buy</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.prddetail}>
          <div className={styles.clusterNode}>
            <div className={styles.outlierNode}>
              <button className={styles.itemLink}>
                <div className={styles.detail}>Detail</div>
              </button>
              <button className={styles.itemLink1}>
                <div className={styles.review}>Review</div>
              </button>
            </div>
          </div>
          <img
              className={styles.icon}
              loading="lazy"
              alt=""
              src="/detailSerum.jpg"
          />
        </section>
        <div className={styles.siblingNodesInner}>
          <div className={styles.frameParent}>
            <div className={styles.listItemLinkWrapper}>
              <div className={styles.listItem}>전성분</div>
            </div>
            <div className={styles.c121412Container}>
              <p className={styles.p}>
                온천수, 프로판다이올, 다이프로필렌글라이콜, 글리세린,
                부틸렌글라이콜, 1,2-헥산다이올, 판테놀,
                암모늄아크릴로일다이메틸타우레이트
              </p>
              <p
                className={styles.c121412}
              >{`/브이피코폴리머, 하이드록시에틸셀룰로오스, 다이포타슘글리시리제이트, C12-14파레스-12, 카프릴릴글라이콜, 에틸헥실글리세린, `}</p>
              <p
                className={styles.p1}
              >{`하이드록시아세토페논, 다이소듐이디티에이, 인도멀구슬나무꽃추출물, 홀리바질잎추출물, 인도멀구슬나무잎추출물, 세라마이드엔피, `}</p>
              <p
                className={styles.p2}
              >{`울금뿌리추출물, 참산호말추출물, 아이비고드열매추출물, 하이드로제네이티드레시틴, 정제수, 글리세릴스테아레이트, 가지열매추출물, 콜레스테롤, `}</p>
              <p className={styles.p3}>
                알로에베라꽃추출물, 세라마이드엔에스, 세라마이드에이피,
                세라마이드에이에스, 세라마이드이오피, 향료, 리모넨, 리날룰,
                헥실신남알
              </p>
            </div>
          </div>
        </div>
      </main>
      <ConnectionNode />
      <Footer
        onListItem4Click={onListItem5Click}
        onListItem5Click={onListItem6Click}
        onListItem1Click={onListItem2Click}
      />
    </div>
  );
};

export default ProductPageSerumSet;
