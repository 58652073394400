import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from "react-router";

function ItemDetail() {
    const {itemId} = useParams();
    const [item, setItem] = useState({
        id : '',
        name : '',
        price : '',
        salePrice : '',
        stock: '',
        content: '',
        imageUrl: ''
    });
    useEffect(() => {
        // 백엔드 서버로부터 공지사항 데이터를 가져옴
        axios.get(`/item/${itemId}`)
            .then(response => {
                if(response.data.code === 200) {
                    setItem(response.data.data);
                } else {
                    console.error("Error loading item");
                }
            })
            .catch(error => {
                console.error("Error fetching item:", error);
            });
    }, [itemId]); // itemId가 변경될 때마다 useEffect 실행

    const navigate = useNavigate();

    const handleDelete = () => {
        axios.delete(`/item/${itemId}`)
            .then(response => {
                console.log(response.data);
                navigate('/item'); // 삭제 후 홈페이지로 이동
            })
            .catch(error => console.error(error));
    };

    const handleButtonClick = () => {
        navigate(`/item/${itemId}/update`); // 페이지 이동
    };

    const [quantity, setQuantity] = useState(1);

    // Adjust quantity
    const increaseQuantity = () => setQuantity(quantity + 1);
    const decreaseQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

    // Submit order
    const submitOrder = async () => {
        console.log(quantity)
        try {
            const response = await axios.post('/order', {
                itemId: item.id,
                count: quantity,
            });
            alert('Order placed successfully!');
        } catch (error) {
            console.error('Failed to place order:', error);
            alert('Failed to place order.');
        }
    };

    const addToCart = async () => {
        try {
            // 장바구니 추가 API 호출
            // 여기서는 예시로 '/cart' 엔드포인트를 사용합니다. 실제 엔드포인트는 백엔드 구현에 따라 달라집니다.
            const response = await axios.post('/cart', {
                itemId: item.id, // 선택된 아이템의 ID
                count: quantity, // 선택된 수량
            });

            // 성공적으로 장바구니에 추가되었을 때의 처리
            alert('Successfully added to cart!');
        } catch (error) {
            // 오류 처리
            console.error('Failed to add to cart:', error);
            alert('Failed to add to cart.');
        }
    };


    return (
        <div className="itemContent">
            <input type="button" value="삭제"  className="btn-input btn-toolbar btn-close-white" onClick={handleDelete}/>
            <input type="button" value="수정"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>

            <div>
                {item.name} <br/>
            </div>
            <div>
                <hr/>
                {item.price} <br/>
            </div>
            <div>
                {item.stock} <br/>
            </div>
            {/*<input type="button" value = "뒤로가기" onClick={handleHistory}  className="backToPage btn btn-toolbar btn-sm"/>*/}
            <div>
                <button onClick={decreaseQuantity}>-</button>
                <span> {quantity} </span>
                <button onClick={increaseQuantity}>+</button>
            </div>
            <button onClick={submitOrder}>주문하기</button>
            <button onClick={addToCart}>장바구니에 추가</button>
        </div>

    );
}

export default ItemDetail;