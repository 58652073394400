import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Footerr from "../../components/Footerr";
import Menu from "../../components/Menu";
import styles from "./CSQnaWrite.module.css";

const CSQnaWrite = () => {
  const navigate = useNavigate();

  const onListItem1Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  const onListItem4Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    navigate("/csmy-page-order");
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onRectangleClick = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onRectangle2Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onListItemClick = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  return (
    <div className={styles.csQnawrite}>
      <div className={styles.rectangleParent}>
        <div className={styles.groupChild} onClick={onRectangleClick} />
        <div className={styles.div}>등록</div>
      </div>
      <div className={styles.rectangleGroup}>
        <div className={styles.groupItem} onClick={onRectangle2Click} />
        <div className={styles.div1}>취소</div>
      </div>
      <div className={styles.listItemContainer}>
        <span>{`QnA `}</span>
        <span className={styles.span}>등록</span>
      </div>
      <div className={styles.csQnawriteChild} />
      <div className={styles.csQnawriteItem} />
      {/*<div className={styles.listItem}>비밀번호</div>*/}
      <div className={styles.csQnawriteInner} />
      <Footerr
        propTop="1687px"
        onListItem1Click={onListItem1Click}
        onListItem4Click={onListItem4Click}
        onListItem5Click={onListItem5Click}
      />
        <div className={styles.listItem2}>
            <input
                type="text"
                placeholder="제목을 입력해주세요"
                className={styles.inputTitle} // Make sure to define or adjust this in your CSS module
            />
        </div>
        <div className={styles.listItem1}>
  <textarea
      placeholder="내용을 입력해주세요"
      className={styles.inputContent} // Make sure to define or adjust this in your CSS module
  />
        </div>
        <div className={styles.listItem}>
            <input
                type="password"
                placeholder="비밀번호"
                className={styles.inputPassword} // Make sure to define or adjust this in your CSS module
            />
        </div>

        {/*<div className={styles.listItem1}>내용을 입력해주세요</div>*/}
      {/*<div className={styles.listItem2}>제목을 입력해주세요</div>*/}
      <div className={styles.csParent}>
        <div className={styles.cs}>CS</div>
        <div className={styles.listItem3} onClick={onListItemClick}>
          Notice
        </div>
        <div className={styles.listItem4}>{`Q&A`}</div>
      </div>
      <Menu
        onHeaderListClick={onHeaderListClick}
        onHeaderList1Click={onHeaderList1Click}
        onHeaderList3Click={onHeaderList3Click}
        onHeaderListItemLinkClick={onHeaderListItemLinkClick}
        onHeaderList4Click={onHeaderList4Click}
        onImageClick={onImageClick}
      />
    </div>
  );
};

export default CSQnaWrite;
