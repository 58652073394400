import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useParams, useNavigate} from "react-router";

function NoticeDetail() {
    const {noticeId} = useParams();
    const [notice, setNotice] = useState({
        id : '',
        title : '',
        content : '',
        date : ''
    });
    useEffect(() => {
        // 백엔드 서버로부터 공지사항 데이터를 가져옴
        axios.get(`/notice/${noticeId}`)
            .then(response => {
                if(response.data.code === 200) {
                    setNotice(response.data.data);
                } else {
                    console.error("Error loading notice");
                }
            })
            .catch(error => {
                console.error("Error fetching notice:", error);
            });
    }, [noticeId]); // noticeId가 변경될 때마다 useEffect 실행

    const navigate = useNavigate();

    const handleDelete = () => {
        axios.delete(`/notice/${noticeId}`)
            .then(response => {
                console.log(response.data);
                navigate('/notice'); // 삭제 후 홈페이지로 이동
            })
            .catch(error => console.error(error));
    };

    const handleButtonClick = () => {
        navigate(`/notice/${noticeId}/update`); // 페이지 이동
    };

    return (
        <div className="noticeContent">
            <input type="button" value="삭제"  className="btn-input btn-toolbar btn-close-white" onClick={handleDelete}/>
            <input type="button" value="수정"  className="btn-input btn-toolbar btn-close-white" onClick={handleButtonClick}/>

            <div>
                {notice.title} <br/>
            </div>
            <div>
                <hr/>
                <textarea readOnly={true} value={notice.content} >
                </textarea>
            </div>
            {/*<input type="button" value = "뒤로가기" onClick={handleHistory}  className="backToPage btn btn-toolbar btn-sm"/>*/}
        </div>

    );
}

export default NoticeDetail;