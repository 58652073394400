import {useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import FrameShopHeader from "../../components/FrameShopHeader";
import Subtree from "../../components/Subtree";
import PrdDetail from "../../components/PrdDetail";
import Footer3 from "../../components/Footer3";
import styles from "./ProductPageMistSet.module.css";
import {useParams} from "react-router";
import axios from "axios";
import {useAuth} from "../../components/AuthContext";

const ProductPageMistSet = () => {
  const navigate = useNavigate();
  const [itemId] = useState(4);
  const [cartItems, setCartItems] = useState({
    id : '',
    name : '',
    price : '',
    salePrice : '',
    stock: '',
    content: '',
    imageUrl: "/thumbMistSet.jpg"
  });
  useEffect(() => {
    // 백엔드 서버로부터 공지사항 데이터를 가져옴
    axios.get(`/item/${itemId}`)
        .then(response => {
          if(response.data.code === 200) {
            setCartItems(response.data.data);
          } else {
            console.error("Error loading item");
          }
        })
        .catch(error => {
          console.error("Error fetching item:", error);
        });
  }, [itemId]); // itemId가 변경될 때마다 useEffect 실행


  const handleDelete = () => {
    axios.delete(`/item/${itemId}`)
        .then(response => {
          console.log(response.data);
          navigate('/item'); // 삭제 후 홈페이지로 이동
        })
        .catch(error => console.error(error));
  };

  const handleButtonClick = () => {
    navigate(`/item/${itemId}/update`); // 페이지 이동
  };

  const [quantity, setQuantity] = useState(1);
  const { isLoggedIn, setIsLoggedIn } = useAuth();


  const onImageClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHeaderList3Click = useCallback(() => {
    navigate("/product-page");
  }, [navigate]);

  const onHeaderListClick = useCallback(() => {
    navigate("/brandstory1");
  }, [navigate]);

  const onHeaderList1Click = useCallback(() => {
    navigate("/csnotice-page");
  }, [navigate]);

  const onHeaderListItemLinkClick = useCallback(() => {
    if (isLoggedIn) {
      navigate('/csmy-page-order');  // 로그인 상태일 때
    } else {
      navigate('/login');  // 로그인 안된 상태일 때
    }
  }, [navigate]);

  const onHeaderList4Click = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const onListItem5Click = useCallback(() => {
    navigate("/privacy-policy");
  }, [navigate]);

  const onListItem6Click = useCallback(() => {
    navigate("/termsofuse");
  }, [navigate]);

  const onListItem2Click = useCallback(() => {
    window.open("https://pf.kakao.com/_xfCsAG/chat");
  }, []);

  // Submit order
  const submitOrder = async () => {
    try {
      let total = quantity *
          navigate('/order', { state: { cartItems, quantity } });
    } catch (error) {
      console.error('Failed to place order:', error);
      alert('Failed to place order.');
    }
  };

  const addToCart = async () => {
    try {
      // 장바구니 추가 API 호출
      // 여기서는 예시로 '/cart' 엔드포인트를 사용합니다. 실제 엔드포인트는 백엔드 구현에 따라 달라집니다.
      const response = await axios.post('/cartcart', {
        itemId: cartItems.id, // 선택된 아이템의 ID
        count: quantity, // 선택된 수량
      });

      // 성공적으로 장바구니에 추가되었을 때의 처리
      alert('Successfully added to cart!');
    } catch (error) {
      // 오류 처리
      // console.error('Failed to add to cart:', error);
      alert('로그인이 필요합니다.');
    }
  };


  return (
    <div className={styles.productPageMistset}>
      <main className={styles.inputProcessor}>
        <FrameShopHeader
          onImageClick={onImageClick}
          onHeaderList3Click={onHeaderList3Click}
          onHeaderListClick={onHeaderListClick}
          onHeaderList1Click={onHeaderList1Click}
          onHeaderListItemLinkClick={onHeaderListItemLinkClick}
          onHeaderList4Click={onHeaderList4Click}
        />
        <section className={styles.edge}>
          <Subtree addToCart={addToCart} submitOrder={submitOrder} />
          <section className={styles.prddetail}>
            <div className={styles.clusterNode}>
              <div className={styles.outlierNode}>
                <button className={styles.itemLink}>
                  <div className={styles.detail}>Detail</div>
                </button>
                <button className={styles.itemLink1}>
                  <div className={styles.review}>Review</div>
                </button>
              </div>
            </div>
            <img
                className={styles.icon}
                loading="lazy"
                alt=""
                src="/detailMist.jpeg"
            />
          </section>
          {/*<PrdDetail*/}
          {/*  propPadding="var(--padding-smi) var(--padding-mid)"*/}
          {/*  propPadding1="var(--padding-smi) 16.600000000000364px var(--padding-smi) var(--padding-mid)"*/}
          {/*/>*/}
          <div className={styles.productPageFrame}>
            <div className={styles.cartFrame}>
              <div className={styles.detailReviewFrame}>
                <div className={styles.listItem}>전성분</div>
              </div>
              <div className={styles.c121412Container}>
                <p className={styles.p}>
                  온천수, 프로판다이올, 다이프로필렌글라이콜, 글리세린,
                  부틸렌글라이콜, 1,2-헥산다이올, 판테놀,
                  암모늄아크릴로일다이메틸타우레이트
                </p>
                <p
                  className={styles.c121412}
                >{`/브이피코폴리머, 하이드록시에틸셀룰로오스, 다이포타슘글리시리제이트, C12-14파레스-12, 카프릴릴글라이콜, 에틸헥실글리세린, `}</p>
                <p
                  className={styles.p1}
                >{`하이드록시아세토페논, 다이소듐이디티에이, 인도멀구슬나무꽃추출물, 홀리바질잎추출물, 인도멀구슬나무잎추출물, 세라마이드엔피, `}</p>
                <p
                  className={styles.p2}
                >{`울금뿌리추출물, 참산호말추출물, 아이비고드열매추출물, 하이드로제네이티드레시틴, 정제수, 글리세릴스테아레이트, 가지열매추출물, 콜레스테롤, `}</p>
                <p className={styles.p3}>
                  알로에베라꽃추출물, 세라마이드엔에스, 세라마이드에이피,
                  세라마이드에이에스, 세라마이드이오피, 향료, 리모넨, 리날룰,
                  헥실신남알
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className={styles.divprddelivery}>
        <h1 className={styles.shippingReturn}>{`Shipping & Return`}</h1>
        <div className={styles.divcommonDetailContent}>
          <div className={styles.audioHandler}>
            <b className={styles.listItem1}>택배사</b>
            <div className={styles.listItem2}>CJ대한통운 (1588-1255)</div>
          </div>
          <div className={styles.listItem3}>
            <b className={styles.b}>배송 비용</b>
            <div className={styles.div}>
              3,000원 (40,000원 이상 구매 시 무료배송), 도서-산간 지역은 별도의
              추가 금액이 발생합니다.
            </div>
          </div>
          <div className={styles.listItem4}>
            <b className={styles.b1}>배송 일정</b>
            <div className={styles.div1}>
              당일 12시 전 주문 시 당일 배송 가능, 지방-도서산간 등은 지역별
              리드타 임에 따라 2-3일 배송일정 소요 가능합니다.
            </div>
          </div>
          <div className={styles.dataStorage}>
            <div className={styles.listItem5}>
              <b className={styles.b2}>배송 조회</b>
              <div className={styles.div2}>
                당일 12시 전 주문 시 당일 배송 가능, 상품의 재고 및 내부 상황에
                따라 다소 지연될 수 있습니다.
              </div>
            </div>
            <div className={styles.listItem6}>
              <b className={styles.b3}>교환 및 반품</b>
              <div className={styles.dataSplitter}>
                <div className={styles.dataRouter}>
                  <div className={styles.listItem7}>·</div>
                  <div className={styles.dataLoader}>
                    <div className={styles.listItem8}>·</div>
                    <div className={styles.listItem9}>·</div>
                    <div className={styles.listItem10}>·</div>
                  </div>
                  <div className={styles.dataFilter}>
                    <div className={styles.listItem11}>·</div>
                    <div className={styles.listItem12}>·</div>
                    <div className={styles.listItem13}>·</div>
                    <div className={styles.listItem14}>·</div>
                  </div>
                </div>
                <div className={styles.dataTransformer}>
                  <div className={styles.listItem15}>
                    단순변심, 착오구매에 따른 교환/반품 신청은 상품을 공급받은
                    날부터 7일 이내 가능합니다. (배송비 고객 부담)
                  </div>
                  <div className={styles.listItem16}>
                    다만, 공급받으신 상품 및 용역의 내용이 표시.광고 내용과
                    다르거나 다르게 이행된 경우에는 공급받은 날로부터 3개월
                    이내, 그 사실을 알게 된 날로부터 30일이내 교환/반품 신청을
                    하실 수 있습니다. (배송비 무료)
                  </div>
                  <div className={styles.listItem17}>
                    구매 시 사은품/ 증정품 등이 제공된 경우, 상품 교환/반품 시
                    함께 보내주셔야 합니다.
                  </div>
                  <div className={styles.listItemContainer}>
                    <p className={styles.p4}>
                      반품 시, 상품대금 환불은 상품 회수 및 청약철회가 확정된
                      날로부터 3영업일 이내 진행되며, 기한을 초과한 경우 지연
                      기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률
                      시행령」 에서 정하는 이율을 곱하여
                    </p>
                    <p className={styles.p5}>산정한 지연이자를 지급합니다.</p>
                  </div>
                  <div className={styles.listItem18}>
                    시간의 경과에 의하여 재판매가 곤란한 정도로 상품 등의 가치가
                    현저히 감소한 경우 교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem19}>
                    고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히
                    감소한 경우 교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem20}>
                    고객님의 책임있는 사유로 상품 등이 멸실 또는 훼손된 경우
                    교환/반품이 불가능합니다.
                  </div>
                  <div className={styles.listItem21}>
                    오프라인 매장에서 구매한 제품은 교환/반품이 불가능합니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.listItem22}>
            <b className={styles.b4}>교환 및 반품 주소</b>
            <div className={styles.div3}>
              [13449] 경기도 성남시 수정구 대왕판교로 815, 7층 744호(시흥동,
              판교창조경제밸리)
            </div>
          </div>
        </div>
      </div>
      <Footer3
        onListItem5Click={onListItem5Click}
        onListItem6Click={onListItem6Click}
        onListItem2Click={onListItem2Click}
      />
    </div>
  );
};

export default ProductPageMistSet;
